import React, { useEffect, useState, useCallback } from 'react';
import { showJourneyModal } from './styles';
import PropTypes from 'prop-types';
import {
    Box,
    Table,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
    withStyles,
    TableCell,
    TableBody,
    Checkbox
} from '@material-ui/core';
import AppointmentApi from '../../api/appointmentApi';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { hideJourneyModal } from '../../actions/appointmentActions';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
import Modal from './Modal';
import { modalsButtonStyles } from '../../collums-constants/styles/stylesheets/buttonsStyles';
import AuthApi from './../../api/authApi';
import getToken from '../../services/helpers/getToken';
import { useCookies } from 'react-cookie';

function ShowJourneyModal({ classes }) {
    const [allAppts, setAllAppts] = useState([]);
    const [enabledConfirmButton, setEnabledConfirmButton] = useState(false);
    const buttonClasses = makeStyles(modalsButtonStyles)();
    const isOpen = useSelector(state => state.appointment.isShowJourneyModal);
    const apptId = useSelector(state => state.appointment.selectedAppointment);
    const dispatch = useDispatch();
    const [, setCookie] = useCookies();

    const dispatchHideJourneyModal = useCallback(() => {
        dispatch(hideJourneyModal());
    }, [dispatch]);

    const startJourney = () => {
        const appts = allAppts.filter(appt => appt.useInJourney);
        if (
            appts.some(appt => appt.journey) &&
            appts.some(appt => appts.some(appt2 => appt.journey !== appt2.journey))
        ) {
            toastr.error('Error', 'Some appointments have different journey');
            return;
        }
        const apptIds = appts.map(appt => appt.id || appt._id).join(',');
        AuthApi.lockUser(setCookie);
        window.location = `${JSON.parse(localStorage.getItem('linkCache')).journeyUrl}/?appointmentId=${apptIds}`;
        dispatchHideJourneyModal();
    };

    useEffect(() => {
        if (apptId && isOpen) {
            setAllAppts([]);
            AppointmentApi.getBack2BackAppts(apptId)
                .then(result => {
                    const appts = result.data;
                    if (!appts.filter(appt => !appt.journey).length || appts.length === 1) {
                        AuthApi.lockUser(setCookie);
                        window.location = `${
                            JSON.parse(localStorage.getItem('linkCache')).journeyUrl
                        }/?appointmentId=${apptId}&token=${getToken()}`;

                        dispatchHideJourneyModal();
                        return;
                    }
                    setAllAppts(result.data);
                })
                .catch(console.info);
        }
    }, [apptId, dispatchHideJourneyModal, isOpen, setCookie]);

    useEffect(() => {
        setEnabledConfirmButton(allAppts.some(appt => appt.useInJourney));
    }, [allAppts]);

    const handleChangeCheckbox = idx => {
        const nAllAppts = [...allAppts];
        nAllAppts[idx].useInJourney = !nAllAppts[idx].useInJourney;
        setAllAppts(nAllAppts);
    };

    if (isOpen && allAppts.length === 0) {
        return <LoadingScreen />;
    }

    const customerName = [
        allAppts[0]?.customer?.firstName,
        allAppts[0]?.customer?.middleName,
        allAppts[0]?.customer?.surname
    ]
        .filter(el => el)
        .join(' ');

    return (
        <Modal
            id="start-journey-modal"
            isOpen={isOpen}
            hideTitle
            cancelLabel="Cancel"
            confirmLabel="Start Journey"
            disableConfirm={!enabledConfirmButton}
            onCancel={() => dispatchHideJourneyModal()}
            onConfirm={startJourney}
            size="md"
            cancelClass={buttonClasses.cancelButton}
            confirmClass={buttonClasses.confirmButton}
        >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography align="left" variant="h3" className={classes.customerName}>
                    {customerName}
                </Typography>

                <Typography align="center" variant="h3">
                    Choose appointments to start journey
                </Typography>
                <Box width="1rem" height="3rem" />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography className={classes.defaultFontSize}>
                                    <strong>Select</strong>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography className={classes.defaultFontSize}>
                                    <strong>Appointment</strong>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allAppts.map((appt, idx) => {
                            return (
                                <TableRow key={idx}>
                                    <TableCell>
                                        <Typography className={classes.defaultFontSize}>
                                            <Checkbox
                                                defaultChecked={appt.useInJourney}
                                                onChange={() => handleChangeCheckbox(idx)}
                                                name="receiveMarketingEmails"
                                                color="primary"
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.defaultFontSize}>
                                            {`${appt.service.name} - ${appt.event.start.format('DD/MM/YYYY HH:mm')}`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        </Modal>
    );
}
ShowJourneyModal.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(showJourneyModal)(ShowJourneyModal);
