import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
    FormControl,
    TextField,
    TablePagination,
    TableSortLabel,
    IconButton,
    Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NotFound from '../../common/NotFound';
import styles from '../styles';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import { tableColumns } from './tableColumns';
import StyledTableRow from '../../common/StyledTableRow';
import Moment from 'moment';
import formStyles from '../../../collums-constants/styles/stylesheets/formStyles';
import queryString from 'query-string';
import { getFormsByCustomer } from '../../../collums-components/api/FormApi';
import { toastr } from 'react-redux-toastr';
import { JOURNEY_FORM_METHODS, JOURNEY_FORM_TYPES, ORGANISATION_FORM_TYPES } from '../../../collums-constants';
import { orderBy } from 'lodash';
import FormModal from './FormModal';
import { getAllFormsByType, getFormLog, getFormToEdit } from './../../../collums-components/api/FormApi';
import EditFormModal from './EditFormModal';
import CustomAutocomplete from './AutoComplete/index';
import HistoryIcon from '@material-ui/icons/History';
import FormHistory from './FormHistory';
import CancelContinueModal from '../../common/CancelContinueModal';
import { useHistory } from 'react-router-dom';

const paginationOptions = [10, 25, 50];

const defaultOrder = {
    direction: 'desc',
    id: 'createdAt'
};

const requestTimeoutModal = +process.env.REACT_APP_MEDICAL_TIMEOUT || 10;

function FormsView({ customer, classes, practitioner, reloadCounts }) {
    const globalFormStyles = makeStyles(formStyles)();
    const history = useHistory();
    const [forms, setForms] = useState([]);
    const [isLoadingForms, setIsLoadingForms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(paginationOptions[1]);
    const [filters, setFilters] = useState({
        type: 'All'
    });
    const [order, setOrder] = useState(defaultOrder);
    const [isShowingVFModal, setIsShowingVFModal] = useState(false);
    const [selectedForm, setSelectedForm] = useState();
    const [availableForms, setAvailableForms] = useState([]);
    const [isShowingEditModal, setIsShowingEditModal] = useState(false);
    const [targetFormLog, setTargetFormLog] = useState();
    const globalStyles = makeStyles(modalsButtonStyles)();
    const intervalRef = useRef(null);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);

    const loadForm = async formItem => {
        try {
            startInterval();
            setIsLoading(true);
            const searchParams = {
                formId: formItem.form.formId || formItem.form.consentId,
                from: formItem.fromCustomer ? 'user forms' : 'journey',
                sectionId: formItem.id
            };
            const formData = await getFormToEdit(searchParams);
            if (formData.model && formData.form) {
                if (formData.model.fields?.length) {
                    const fields = formData.model.fields.map(field => {
                        if (formData.form.data && formData.form.data[field.id]) {
                            return { ...field, ...formData.form.data[field.id] };
                        }
                        return field;
                    });
                    setSelectedForm({
                        id: formItem.id,
                        form: {
                            ...formData.form,
                            fields
                        },
                        model: formData.model,
                        fromCustomer: formItem.fromCustomer
                    });
                    setIsShowingEditModal(true);
                } else if (formData.model.consent) {
                    setSelectedForm({
                        id: formItem.id,
                        form: formData.form,
                        model: formData.model,
                        fromCustomer: formItem.fromCustomer
                    });
                    setIsShowingEditModal(true);
                }
            }
        } finally {
            setIsLoading(false);
            stopInterval();
            setShowTimeoutModal(false);
        }
    };

    const handleSort = column => {
        if (column.id === order.id) {
            const direction = order.direction === 'asc' ? 'desc' : 'asc';
            setOrder({
                ...order,
                direction
            });
        } else {
            setOrder({
                direction: 'asc',
                id: column.id
            });
        }
    };

    const openLog = async formItem => {
        try {
            setIsLoading(true);
            const [id, journey] = (() => {
                if (formItem.fromCustomer) return [formItem.id];
                return [formItem.model.id, formItem.id];
            })();
            const isConsent = formItem.type === ORGANISATION_FORM_TYPES.CONSENT;
            const formWithLogs = await getFormLog(id, journey, isConsent);
            setTargetFormLog(formWithLogs);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong (code: c0034)');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllForms = () => {
        getAllFormsByType(queryString.stringify(filters)).then(res => setAvailableForms(res));
    };

    const startInterval = () => {
        let timer = 0;
        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                timer++;
                if (timer >= requestTimeoutModal) {
                    setShowTimeoutModal(true);
                    clearInterval(intervalRef.current);
                    stopInterval();
                }
            }, 1000);
        }
    };
    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const fetchForms = async () => {
        try {
            if (customer) {
                startInterval();
                setIsLoading(true);
                setIsLoadingForms(true);
                const data = await getFormsByCustomer(customer.id, queryString.stringify(filters));
                stopInterval();
                setShowTimeoutModal(false);
                reloadCounts();
                setOrder(defaultOrder);
                setPage(0);
                setForms(data);
            }
        } catch (err) {
            toastr.error('Could not load forms');
        } finally {
            setIsLoading(false);
            setIsLoadingForms(false);
        }
    };

    useEffect(() => {
        fetchAllForms();
        fetchForms();
        //eslint-disable-next-line
    }, [customer, filters]);

    useEffect(() => {
        const orderFunction = (() => {
            switch (order.id) {
                case 'createdAt':
                    return o => {
                        return new Date(o.form.createdAt).getTime();
                    };
                case 'name':
                    return o => {
                        return o.model.name;
                    };
                case 'service':
                    return o => {
                        return o.serviceName || '';
                    };
                case 'staff':
                    return o => {
                        return o.staffName || '';
                    };
                case 'method':
                    return o => {
                        return o.form.method || '';
                    };
                default:
                    return o => {
                        return o[order.id] || '';
                    };
            }
        })();
        const orderedForm = orderBy(forms, orderFunction, [order.direction]);
        setForms(orderedForm);
        //eslint-disable-next-line
    }, [order]);

    const getSignedValue = data => {
        const getValue = value => (value.isSigned ? value.form.method : 'No');

        if (data?.model?.type === JOURNEY_FORM_TYPES.CONSENT) {
            return getValue(data);
        } else {
            if (data?.hasSignatureField) {
                return getValue(data);
            }

            return 'N/a';
        }
    };

    const getAbnormalityValue = data => {
        return data?.abnormality || '';
    };

    const getHasChangedValue = data => {
        return data?.didChanges || '';
    };

    const getStaffName = data => {
        return data?.form?.method === JOURNEY_FORM_METHODS.ONLINE ? 'Online' : data.staffName || '';
    };

    const FormTable = useMemo(() => {
        const displayingRows = forms.slice(page * pageSize, page * pageSize + pageSize);
        return (
            <>
                <Table size="medium" stickyHeader className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {tableColumns.map(column => (
                                <TableCell key={column.id} align="left" className={classes.tableHeader}>
                                    <TableSortLabel
                                        active={column.id === order.id}
                                        direction={order.direction}
                                        onClick={() => handleSort(column)}
                                    >
                                        {column.name}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayingRows.map((formItem, index) => {
                            return (
                                <StyledTableRow key={`${formItem.id}-${index}`}>
                                    {tableColumns.map(column => {
                                        const value = (() => {
                                            switch (column.id) {
                                                case 'createdAt':
                                                    return Moment(formItem.form.createdAt).format('DD/MM/YYYY HH:mm');
                                                case 'name':
                                                    return formItem.model?.name;
                                                case 'service':
                                                    return formItem.serviceName;
                                                case 'staff':
                                                    if (formItem.form.method === JOURNEY_FORM_METHODS.IN_PERSON) {
                                                        return formItem.staff?.displayName;
                                                    }
                                                    return '';
                                                case 'method':
                                                    return getSignedValue(formItem);
                                                case 'abnormality':
                                                    return getAbnormalityValue(formItem);
                                                case 'didChanges':
                                                    return getHasChangedValue(formItem);
                                                case 'staffName':
                                                    return getStaffName(formItem);
                                                case 'log':
                                                    return (
                                                        <IconButton
                                                            onClick={() => {
                                                                openLog(formItem);
                                                            }}
                                                        >
                                                            <HistoryIcon />
                                                        </IconButton>
                                                    );
                                                default:
                                                    return formItem[column.id];
                                            }
                                        })();

                                        const shouldUseRedText =
                                            ['abnormality', 'didChanges'].includes(column.id) && value === 'Yes';

                                        if (column.id === 'name') {
                                            return (
                                                <TableCell
                                                    className={`${classes.tableBodyCellLarge} ${classes.link}`}
                                                    key={`${formItem.id}-${index}-${column.id}`}
                                                    onClick={() => {
                                                        loadForm(formItem);
                                                    }}
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        }

                                        return (
                                            <TableCell
                                                className={`${classes.tableBodyCellLarge} ${shouldUseRedText &&
                                                    classes.redText}`}
                                                key={`${formItem.id}-${index}-${column.id}`}
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </>
        );
        //eslint-disable-next-line
    }, [forms, page, pageSize, order]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Box p={3}>
                <Grid container direction="column">
                    <Box item display={'flex'}>
                        <Box
                            display="flex"
                            width="15%"
                            flexDirection="column"
                            justifyContent="space-between"
                            marginLeft={0}
                            marginRight={2}
                        >
                            <FormControl className={classes.formControl}>
                                <CustomAutocomplete
                                    options={[
                                        'All',
                                        ORGANISATION_FORM_TYPES.CONSENT,
                                        ORGANISATION_FORM_TYPES.GENERAL,
                                        ORGANISATION_FORM_TYPES.MEDICAL,
                                        ORGANISATION_FORM_TYPES.AFTERCARE
                                    ]}
                                    className={globalFormStyles.autocompleteWithPadding}
                                    getOptionLabel={option => option}
                                    onChange={(_, value) => {
                                        setFilters({
                                            ...filters,
                                            type: value || 'All'
                                        });
                                    }}
                                    disableClearable
                                    renderInput={params => (
                                        <TextField
                                            className={globalFormStyles.autocompleteTextField}
                                            {...params}
                                            label="Form type"
                                            variant="outlined"
                                        />
                                    )}
                                    value={filters.type}
                                />
                            </FormControl>
                        </Box>
                        <Box display="flex">
                            <Button
                                id="view-form-button"
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={`${globalStyles.baseButton} ${classes.blueButton} ${classes.noMargin} ${classes.fixedSize}`}
                                style={{ fontSize: 14 }}
                                onClick={() => setIsShowingVFModal(true)}
                            >
                                View forms
                            </Button>
                        </Box>
                    </Box>
                    <Grid item className={classes.electronicNotesTableGrid} style={{ marginTop: 16 }}>
                        {forms.length > 0 ? FormTable : !isLoadingForms ? <NotFound /> : ''}
                    </Grid>
                    {forms.length > 0 && (
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={paginationOptions}
                                component="div"
                                count={forms.length}
                                rowsPerPage={pageSize}
                                page={page}
                                onChangePage={(e, page) => {
                                    setPage(page);
                                }}
                                onRowsPerPageChange={e => {
                                    setPageSize(parseInt(e.target.value, 10));
                                    setPage(0);
                                }}
                                classes={{ root: classes.tablePagination }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            {isShowingVFModal && (
                <FormModal
                    availableForms={availableForms}
                    onClose={(shouldReload = false) => {
                        setIsShowingVFModal(false);
                        if (shouldReload) fetchForms();
                    }}
                    customer={customer}
                />
            )}
            {isShowingEditModal && (
                <EditFormModal
                    customer={customer}
                    reload={fetchForms}
                    selectedItem={selectedForm}
                    onCancel={() => setIsShowingEditModal(false)}
                    practitioner={practitioner}
                />
            )}
            {targetFormLog && <FormHistory form={targetFormLog} onCancel={() => setTargetFormLog()} />}
            {showTimeoutModal && (
                <CancelContinueModal
                    setOpen={setShowTimeoutModal}
                    title={'Slow internet connection'}
                    customLabel={'Continue'}
                    customCancelLabel={'Go back'}
                    onContinue={() => {}}
                    onCancel={() => {
                        history.go(-1);
                    }}
                >
                    <Typography>
                        This page is taking longer to load than usual. <br />
                        You can continue to wait or go back and try again.
                    </Typography>
                </CancelContinueModal>
            )}
        </>
    );
}

FormsView.propTypes = {
    classes: PropTypes.object.isRequired,
    customer: PropTypes.object,
    practitioner: PropTypes.object.isRequired,
    reloadCounts: PropTypes.func.isRequired
};

export default withStyles(styles)(FormsView);
