import React, { useState } from 'react';

import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';

import { Modal, Paper, withStyles, Button, Typography, IconButton, makeStyles } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import Item from './TemplateItem';

import PropTypes from 'prop-types';

import styles from './styles';
import ConfirmExitModal from './../ConfirmExitModal/ConfirmExitModal';
import LoadingScreen from './../loadingScreen';
import { toastr } from 'react-redux-toastr';

const DeleteTemplatesModal = ({
    classes,
    deleteAction,
    templates,
    isOpen,
    successMessage,
    setIsOpen,
    refreshTemplates
}) => {
    const modalButtonClasses = makeStyles(modalsButtonStyles)();

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    const handleClose = () => {
        setIsOpen(false);
        setSelectedTemplates([]);
    };

    const handleSelectItem = item => {
        if (selectedTemplates.includes(item)) {
            setSelectedTemplates(selectedTemplates.filter(_t => _t !== item));
        } else {
            setSelectedTemplates([...selectedTemplates, item]);
        }
    };

    const renderTemplates = () => {
        return templates.map(template => (
            <Item key={template.id} id={template.id} name={template.name} itemSelected={handleSelectItem} />
        ));
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await deleteAction(selectedTemplates);
            if (successMessage) toastr.success(successMessage);
            if (refreshTemplates) refreshTemplates();
            setSelectedTemplates([]);
            setShowConfirm(false);
            setIsOpen(false);
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal id="delete-template-modal" open={isOpen} onClose={handleClose} className={classes.modalContainer}>
                <Paper className={`${classes.modalPaper} ${classes.manageTemplateModal}`}>
                    <div className={classes.modalHeader}>
                        <Typography className={classes.title}>Delete template</Typography>
                        <IconButton onClick={handleClose} className={classes.closeButton}>
                            <Clear />
                        </IconButton>
                    </div>
                    {templates.length ? renderTemplates() : 'No templates added'}
                    <div className={classes.itemSpacingContainer}>
                        <Button className={modalButtonClasses.cancelButton} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            className={modalButtonClasses.confirmButton}
                            onClick={() => {
                                if (selectedTemplates.length) {
                                    setShowConfirm(true);
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Paper>
            </Modal>
            {showConfirm && (
                <ConfirmExitModal
                    id="confirm-delete-template-modal"
                    isOpen
                    onConfirm={handleDelete}
                    onCancel={() => setShowConfirm(false)}
                    title={<Typography className={classes.deleteTitle}>Delete</Typography>}
                    text={
                        <Typography className={classes.content}>
                            Are you sure you want to delete{' '}
                            {selectedTemplates.length > 1 ? 'these templates' : 'this template'}?
                        </Typography>
                    }
                />
            )}
            {isLoading && <LoadingScreen />}
        </>
    );
};

DeleteTemplatesModal.propTypes = {
    classes: PropTypes.object.isRequired,
    deleteAction: PropTypes.func.isRequired,
    templates: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    successMessage: PropTypes.string,
    setIsOpen: PropTypes.func.isRequired,
    refreshTemplates: PropTypes.func
};

export default withStyles(styles)(DeleteTemplatesModal);
