import _ from 'lodash';
import Moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import { toastr } from 'react-redux-toastr';
import appointmentApi from '../api/appointmentApi';
import CalendarApi from '../api/calendarApi';
import CustomerApi from '../api/customerApi';
import practitionerApi from '../api/practitionerApi';
import roomApi from '../api/roomApi';
import serviceApi from '../api/serviceApi';
import AppointmentApi from '../api/appointmentApi';
import { getAllocatedTime, stringToMomentTransformer } from '../services/helpers';
import { showError } from '../services/interceptors';
import * as actions from './actionTypes';
import { EQUIPMENT, ROOMS, STAFF } from '../constants/viewModes';
import { PRACTITIONER_ORDER } from '../constants/LocalStorage';
import { differenceInMinutes } from 'date-fns';
import getPractitionerPrice from '../services/helpers/getPractitionerPrice';
import { uploadCustomerAvatar } from '../collums-components/api/CustomerApi';
import getClinicHours from './../services/helpers/getClinicHours';
import { fetchCustomerCourses } from '../actions/customerActions';
import { isArray } from 'lodash';
import { sendSentryMessage } from '../collums-components/helpers/sentry';

export function loadDayScheduleSuccess(daySchedule) {
    return { type: actions.LOAD_DAY_SCHEDULE_SUCCESS, payload: daySchedule };
}

export function loadDayScheduleSuccessWithoutLoading(daySchedule) {
    return { type: actions.LOAD_DAY_SCHEDULE_SUCCESS_WITHOUT_RELOAD, payload: daySchedule };
}

export function loadDayScheduleRequest() {
    return { type: actions.LOAD_DAY_SCHEDULE_REQUEST };
}

const mapAppointment = appointment => {
    const starts = Moment(appointment.event.start);
    const ends = Moment(appointment.event.end);
    const diff = differenceInMinutes(ends.toDate(), starts.toDate());

    const service = appointment.service || {};
    const customer = appointment.customer || {};
    const price = (() => {
        if (appointment.course) return 0;
        if (typeof appointment.price === 'number') return appointment.price;
        const defaultValues = getPractitionerPrice(service, appointment?.practitioner?.id, appointment.clinic);
        if (defaultValues) return defaultValues.defaultGrossPrice;
        else return service.defaultGrossPrice;
    })();

    return {
        id: appointment.id,
        event: appointment.event,
        duration: diff,
        isFirstAppointment: appointment.isFirstAppointment || false,
        isCanceled: appointment.isCanceled,
        type: appointment.type,
        title: appointment.title,
        breakType: appointment.breakType,
        notesPopsUp: appointment.popup,
        equipment: appointment.equipment,
        leaveType: appointment.leaveType,
        repeats: appointment.repeats,
        journey: appointment.journey,
        repeatsOccurrences: appointment.repeatsOccurrences,
        repeatsEndCondition: appointment.repeatsEndCondition,
        repeatsLastOccurrence: appointment.repeatsLastOccurrence,
        recurringGroup: appointment.recurringGroup,
        notes: appointment.notes,
        customer: { ...customer, email: customer.email },
        practitioner: appointment.practitioner,
        practitioners: appointment.practitioners || [appointment.practitioner],
        isPractitionerRequired: appointment.isPractitionerRequired,
        groupId: appointment.groupId,
        service: appointment.service,
        status: appointment.status,
        room: appointment.room,
        price: price,
        notification: appointment.notification,
        createdBy: appointment.createdBy,
        invoice: appointment.invoice,
        isLinked: appointment.isLinked,
        linkedAppointments: (appointment.linkedAppointments || []).map(mapAppointment),
        isDayBlocker: appointment.isDayBlocker ? true : false,
        isDayBlockerExceptAppointments: appointment.isDayBlockerExceptAppointments ? true : false,
        course: appointment.course,
        isPaid: appointment.isPaid ? true : false,
        locations: appointment.locations,
        multipleDays: appointment.multipleDays,
        isRecurring: appointment.isRecurring,
        checkedIn: appointment.checkedIn,
        history: appointment.history,
        bookedOnline: appointment.bookedOnline
    };
};

function openIndexedDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('CollumsCache', 1);

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            db.createObjectStore('schedules', { keyPath: 'id' });
        };

        request.onsuccess = function(event) {
            const db = event.target.result;
            resolve(db);
        };

        request.onerror = function(event) {
            console.error('IndexedDB failed to open:', event.target.error);
            resolve(null);
            // reject(new Error('IndexedDB failed to open: ' + event.target.error));
        };
    });
}

function saveToIndexedDB(db, storeName, key, data) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.put({ id: key, ...data });

        request.onsuccess = function() {
            resolve();
        };

        request.onerror = function() {
            reject(new Error('Failed to save data to IndexedDB.'));
        };
    });
}

function getFromIndexedDB(db, storeName, key) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.get(key);

        request.onsuccess = function(event) {
            const result = event.target.result;
            resolve(result);
        };

        request.onerror = function() {
            reject(new Error('Failed to get data from IndexedDB.'));
        };
    });
}

export function loadDayPractitionersSchedule({ weekViewPractitioner, date, clinic, force = false }) {
    const newDate = date.clone().set({ hours: 12, minutes: 0 });
    const actionFactory = async () => {
        let response = {};
        try {
            if (weekViewPractitioner) {
                return await CalendarApi.getPractitionerWeekSchedules(weekViewPractitioner, clinic.id, newDate, false);
            }

            const date7daysAgo = Moment()
                .subtract(7, 'days')
                .startOf('day')
                .toISOString();
            const date1dayAgo = Moment()
                .subtract(1, 'days')
                .endOf('day')
                .toISOString();
            const newDateString = newDate.toISOString();
            const currentTime = new Date();
            const cacheTimeInMinutes = 60;

            const db = await openIndexedDB();
            const cacheKey = `cache-schedule-${newDateString}-${clinic.id}`;

            if (db) {
                const cacheVal = await getFromIndexedDB(db, 'schedules', cacheKey);
                if (cacheVal) {
                    const cacheValidDate = new Date();
                    cacheValidDate.setMinutes(cacheValidDate.getMinutes() - cacheTimeInMinutes);
                    if (cacheValidDate.toISOString() < cacheVal.cached) {
                        return { data: stringToMomentTransformer(JSON.parse(cacheVal.data)) };
                    }
                }
            }

            response = await CalendarApi.getDayPractitionersScheduleForDate(clinic.id, newDate, undefined, force);
            if (date7daysAgo < newDate.toISOString() && date1dayAgo > newDate.toISOString()) {
                if (db) {
                    await saveToIndexedDB(db, 'schedules', cacheKey, {
                        cached: currentTime.toISOString(),
                        data: JSON.stringify(response.data)
                    });
                }
            }
            return response;
        } catch (e) {
            sendSentryMessage('Calendar date switch error: ' + JSON.stringify(e));
            sendSentryMessage('Calendar date switch error response: ' + JSON.stringify(response));
            console.log('CURRENT ERROR', e);

            toastr.error('Error', 'Something went wrong (code: c0004)');
        }
    };

    return dispatch => {
        dispatch(loadDayScheduleRequest());
        trackPromise(
            actionFactory()
                .then(result => {
                    const schedulesData = (() => {
                        if (isArray(result?.data)) {
                            return result.data;
                        }
                        return [];
                    })();

                    // Returns either appointments from a column or all appointments
                    const getAppointment = index => {
                        const appointments = schedulesData.map(schedule => schedule.appointments.filter(appt => appt));
                        if (index === undefined) return appointments.flat().map(mapAppointment);
                        return appointments[index]?.map(mapAppointment) || [];
                    };

                    // Returns every First Appointment's customer id
                    const appts = getAppointment();
                    /**
                     * TODO: add a better validation on it
                     */
                    if (!(appts && isArray(appts))) return;
                    const firstApptList = appts
                        .filter(appt => {
                            const hasFirstAppointment = (appt.linkedAppointments || []).some(
                                el => el.isFirstAppointment
                            );
                            return appt.type === 'Appointment' && (appt.isFirstAppointment || hasFirstAppointment);
                        })
                        .map(appt => {
                            return {
                                id: appt.id,
                                customer: appt.customer.id,
                                date: appt.event.start.format('DD/MM/YYYY'),
                                end: appt.event.end,
                                hasStart: true
                            };
                        });

                    const daySchedule = {
                        date,
                        schedules: schedulesData.map((schedule, index) => {
                            if (!schedule.practitionerSchedules) {
                                schedule.practitionerSchedules = [];
                            }
                            const clinicHours = (() => {
                                if (weekViewPractitioner) {
                                    return getClinicHours(clinic, date.clone().add(index, 'day'));
                                }
                                return getClinicHours(clinic, date);
                            })();

                            const linkedAppts = _.flattenDeep(
                                schedulesData.map(scheduleItem => {
                                    return (scheduleItem.appointments || []).map(appt => {
                                        return (appt.linkedAppointments || []).filter(linkedAppt => {
                                            return linkedAppt?.practitioner?.id === schedule.id;
                                        });
                                    });
                                })
                            );

                            const allocatedAppointmentsTime = getAllocatedTime(
                                [...schedule.appointments, ...linkedAppts],
                                schedule.practitionerSchedules,
                                null,
                                schedule.practitionerSchedules[0] ? schedule.practitionerSchedules[0].date : date,
                                clinicHours.start,
                                clinicHours.end,
                                false
                            );

                            // Gets appointment from column and mark them as first
                            // appointment a customer has scheduled one in the same day
                            const appointments = getAppointment(index).map((appt, i, appts) => {
                                const shouldIncludeStar = (() => {
                                    // find if there is some first star appt for this customer
                                    const firstAppt = firstApptList.find(
                                        starAppt => appt.customer.id === starAppt.customer
                                    );

                                    // if not, do not include star
                                    if (!firstAppt) return false;

                                    // if it's the first appt, should include star
                                    if (appt.id === firstAppt.id) return true;

                                    // if there is, get all customer appts
                                    const customerAppts = _.orderBy(
                                        appts.filter(appointment => appointment.customer.id === appt.customer.id),
                                        'event.start'
                                    );

                                    if (!customerAppts) {
                                        // this should never happen, didn't find himself neither the first one
                                        return false;
                                    }

                                    // if there is only one customer appt besides the first, calculate the diff directly
                                    if (customerAppts.length === 2) {
                                        if (
                                            firstAppt.date === appt.event.start.format('DD/MM/YYYY') &&
                                            appt.event.start.diff(Moment(firstAppt.end), 'minutes') > 60
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    }

                                    let includeStar = true;

                                    // check every appt until the current one, to check if it's the same visit
                                    while (includeStar) {
                                        if (!customerAppts.length) {
                                            return includeStar;
                                        } else if (customerAppts[0]?.id === appt.id) {
                                            // if it gets to the current appt, should include star (we already calculated the current with the previous)
                                            return includeStar;
                                        } else if (
                                            // check if the first 2 appts are not in the same visit
                                            customerAppts[0].event.start.format('DD/MM/YYYY') ===
                                                customerAppts[1].event.start.format('DD/MM/YYYY') &&
                                            customerAppts[1].event.start.diff(
                                                Moment(customerAppts[0].event.end),
                                                'minutes'
                                            ) > 60
                                        ) {
                                            includeStar = false;
                                        }
                                        // remove the first appt from the array
                                        customerAppts.shift();
                                    }
                                    return includeStar;
                                })();

                                if (shouldIncludeStar) {
                                    appt.isFirstAppointment = true;
                                    appt.linkedAppointments = (appt.linkedAppointments || []).map(lnkdAppt => {
                                        return {
                                            ...lnkdAppt,
                                            isFirstAppointment: true
                                        };
                                    });
                                }
                                return appt;
                            });

                            return {
                                date: schedule.date,
                                practitioner: {
                                    id: schedule.id,
                                    displayName: schedule.displayName,
                                    avatar: schedule.avatar,
                                    notes: schedule.notes,
                                    isSoloPractitioner: schedule.isSoloPractitioner
                                },
                                practitionerSchedules: schedule.practitionerSchedules,
                                allocatedPercentage: allocatedAppointmentsTime,
                                appointments,
                                isScheduleFull: allocatedAppointmentsTime === 100
                            };
                        })
                    };

                    if (weekViewPractitioner) {
                        dispatch(setIsDateChange(false));
                        return dispatch(loadDayScheduleSuccess(daySchedule));
                    }

                    const order = localStorage.getItem(PRACTITIONER_ORDER);
                    if (!order) {
                        localStorage.setItem(
                            PRACTITIONER_ORDER,
                            daySchedule.schedules.map(sch => sch.practitioner.id).join(',')
                        );

                        dispatch(setIsDateChange(false));
                        return dispatch(loadDayScheduleSuccess(daySchedule));
                    }

                    let reorderedSchedule = order
                        .split(',')
                        .map(id => daySchedule.schedules.find(schedule => schedule.practitioner.id === id))
                        .filter(x => !!x);
                    reorderedSchedule = [
                        ...reorderedSchedule,
                        ...daySchedule.schedules
                            .filter(sch => !order.split(',').includes(sch.practitioner.id))
                            .map(schedule => ({
                                ...schedule,
                                date: null
                            }))
                    ];

                    dispatch(
                        setCalendarViewMode({
                            viewMode: STAFF
                        })
                    );
                    dispatch(setIsDateChange(false));
                    return dispatch(loadDayScheduleSuccess({ ...daySchedule, schedules: reorderedSchedule }));
                })
                .catch(err => {
                    console.error(err);
                    if (err?.data?.message) {
                        toastr.error(err.data.message);
                    }
                })
        );
    };
}

// ? ROOMS

export function loadPractitionersAvailable({ date, clinic }) {
    const newDate = date.clone().set({ hours: 12, minutes: 0 });
    const practitionersAvailable = () =>
        trackPromise(CalendarApi.getAvailablePractitioners(clinic.id, newDate.toISOString()));

    return dispatch => {
        practitionersAvailable()
            .then(result => {
                return dispatch(
                    loadDayScheduleSuccessWithoutLoading({
                        availablePractitioners: result.data
                    })
                );
            })
            .catch(showError);
    };
}

export function loadDayRoomSchedule({ date, clinic, force = false }) {
    const newDate = date.clone().set({ hours: 12, minutes: 0 });
    const actionFactory = async () => {
        return await CalendarApi.getDayRoomsScheduleForDate(clinic.id, newDate, force);
    };

    return dispatch => {
        dispatch(loadDayScheduleRequest());
        trackPromise(
            actionFactory()
                .then(result => {
                    const clinicHours = getClinicHours(clinic, date);

                    const allAppts = _.flattenDeep(result.data.map(schedule => schedule.appointments));

                    const daySchedule = {
                        date,
                        schedules: result.data.map(schedule => {
                            const linkedAppts = _.flattenDeep(
                                result.data.map(scheduleItem => {
                                    return (scheduleItem.appointments || []).map(appt => {
                                        return (appt.linkedAppointments || []).filter(linkedAppt => {
                                            return linkedAppt?.room?.id === schedule.id;
                                        });
                                    });
                                })
                            );

                            const allocatedAppointmentsTime = getAllocatedTime(
                                [...(schedule.appointments || []), ...linkedAppts],
                                schedule.roomSchedules,
                                null,
                                schedule.roomSchedules[0] ? schedule.roomSchedules[0].date : date,
                                clinicHours.start,
                                clinicHours.end,
                                false
                            );

                            const appts = schedule.appointments.map(mapAppointment).filter(appt => appt);

                            const firstApptList = allAppts
                                .filter(appt => {
                                    const hasFirstAppointment = (appt.linkedAppointments || []).some(
                                        el => el.isFirstAppointment
                                    );
                                    return (
                                        appt.type === 'Appointment' && (appt.isFirstAppointment || hasFirstAppointment)
                                    );
                                })
                                .map(appt => {
                                    return {
                                        id: appt.id,
                                        customer: appt.customer.id,
                                        date: appt.event.start.format('DD/MM/YYYY'),
                                        end: appt.event.end,
                                        hasStart: true
                                    };
                                });

                            // Gets appointment from column and mark them as first
                            // appointment a customer has scheduled one in the same day
                            const appointments = appts.map((appt, i, appts) => {
                                const shouldIncludeStar = (() => {
                                    // find if there is some first star appt for this customer
                                    const firstAppt = firstApptList.find(
                                        starAppt => appt.customer.id === starAppt.customer
                                    );

                                    // if not, do not include star
                                    if (!firstAppt) return false;

                                    // if it's the first appt, should include star
                                    if (appt.id === firstAppt.id) return true;

                                    // if there is, get all customer appts
                                    const customerAppts = _.orderBy(
                                        appts.filter(appointment => appointment.customer.id === appt.customer.id),
                                        'event.start'
                                    );

                                    if (!customerAppts) {
                                        // this should never happen, didn't find himself neither the first one
                                        return false;
                                    }

                                    // if there is only one customer appt besides the first, calculate the diff directly
                                    if (customerAppts.length === 2) {
                                        if (
                                            firstAppt.date === appt.event.start.format('DD/MM/YYYY') &&
                                            appt.event.start.diff(Moment(firstAppt.end), 'minutes') > 60
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    }

                                    let includeStar = true;

                                    // check every appt until the current one, to check if it's the same visit
                                    while (includeStar) {
                                        if (!customerAppts.length) {
                                            return includeStar;
                                        } else if (customerAppts[0]?.id === appt.id) {
                                            // if it gets to the current appt, should include star (we already calculated the current with the previous)
                                            return includeStar;
                                        } else if (
                                            // check if the first 2 appts are not in the same visit
                                            customerAppts[0].event.start.format('DD/MM/YYYY') ===
                                                customerAppts[1].event.start.format('DD/MM/YYYY') &&
                                            customerAppts[1].event.start.diff(
                                                Moment(customerAppts[0].event.end),
                                                'minutes'
                                            ) > 60
                                        ) {
                                            includeStar = false;
                                        }
                                        // remove the first appt from the array
                                        customerAppts.shift();
                                    }
                                    return includeStar;
                                })();

                                if (shouldIncludeStar) {
                                    appt.isFirstAppointment = true;
                                    appt.linkedAppointments = (appt.linkedAppointments || []).map(lnkdAppt => {
                                        return {
                                            ...lnkdAppt,
                                            isFirstAppointment: true
                                        };
                                    });
                                }
                                return appt;
                            });

                            return {
                                date: schedule.date,
                                room: {
                                    id: schedule.id,
                                    name: schedule.name
                                },
                                roomSchedules: schedule.roomSchedules,
                                allocatedPercentage: allocatedAppointmentsTime,
                                appointments,
                                isScheduleFull: allocatedAppointmentsTime === 100
                            };
                        })
                    };

                    dispatch(
                        setCalendarViewMode({
                            viewMode: ROOMS
                        })
                    );

                    dispatch(setIsDateChange(false));
                    return dispatch(loadDayScheduleSuccess(daySchedule));
                })
                .catch(showError)
        );
    };
}

export function loadDayEquipmentSchedule({ date, clinicStartHour, clinicEndHour, force = false, clinic }) {
    const actionFactory = async () => {
        return await CalendarApi.getDayEquipmentScheduleForDate(date, force, clinic.id);
    };
    return dispatch => {
        dispatch(loadDayScheduleRequest());
        trackPromise(
            actionFactory()
                .then(async result => {
                    const equipmentSchedules = result.data;

                    const schedule = {
                        date,
                        schedules: result.data.map(schedule => {
                            const appts = equipmentSchedules.reduce((acc, value) => {
                                return [...acc, ...(value.appointments || [])];
                            }, []);

                            const firstApptList = appts
                                .filter(appt => {
                                    const hasFirstAppointment = (appt.linkedAppointments || []).some(
                                        el => el.isFirstAppointment
                                    );
                                    return (
                                        appt.type === 'Appointment' && (appt.isFirstAppointment || hasFirstAppointment)
                                    );
                                })
                                .map(appt => {
                                    return {
                                        id: appt.id,
                                        customer: appt.customer.id,
                                        date: appt.event.start.format('DD/MM/YYYY'),
                                        end: appt.event.end,
                                        hasStart: true
                                    };
                                });

                            const apptsPerEquipment = appts
                                .filter(appt => {
                                    return (
                                        appt.service &&
                                        appt?.equipment &&
                                        appt?.equipment === schedule.id &&
                                        appt.service.locations.some(location => {
                                            return location.clinic === clinic.id;
                                        })
                                    );
                                })
                                .map(appt => {
                                    const shouldIncludeStar = (() => {
                                        // find if there is some first star appt for this customer
                                        const firstAppt = firstApptList.find(
                                            starAppt => appt.customer.id === starAppt.customer
                                        );

                                        // if not, do not include star
                                        if (!firstAppt) return false;

                                        // if it's the first appt, should include star
                                        if (appt.id === firstAppt.id) return true;

                                        // if there is, get all customer appts
                                        const customerAppts = _.orderBy(
                                            appts.filter(appointment => appointment.customer.id === appt.customer.id),
                                            'event.start'
                                        );

                                        if (!customerAppts) {
                                            // this should never happen, didn't find himself neither the first one
                                            return false;
                                        }

                                        // if there is only one customer appt besides the first, calculate the diff directly
                                        if (customerAppts.length === 2) {
                                            if (
                                                firstAppt.date === appt.event.start.format('DD/MM/YYYY') &&
                                                appt.event.start.diff(Moment(firstAppt.end), 'minutes') > 60
                                            ) {
                                                return false;
                                            }
                                            return true;
                                        }

                                        let includeStar = true;

                                        // check every appt until the current one, to check if it's the same visit
                                        while (includeStar) {
                                            if (!customerAppts.length) {
                                                return includeStar;
                                            } else if (customerAppts[0]?.id === appt.id) {
                                                // if it gets to the current appt, should include star (we already calculated the current with the previous)
                                                return includeStar;
                                            } else if (
                                                // check if the first 2 appts are not in the same visit
                                                customerAppts[0].event.start.format('DD/MM/YYYY') ===
                                                    customerAppts[1].event.start.format('DD/MM/YYYY') &&
                                                customerAppts[1].event.start.diff(
                                                    Moment(customerAppts[0].event.end),
                                                    'minutes'
                                                ) > 60
                                            ) {
                                                includeStar = false;
                                            }
                                            // remove the first appt from the array
                                            customerAppts.shift();
                                        }
                                        return includeStar;
                                    })();

                                    if (shouldIncludeStar) {
                                        appt.isFirstAppointment = true;
                                        appt.linkedAppointments = (appt.linkedAppointments || []).map(lnkdAppt => {
                                            return {
                                                ...lnkdAppt,
                                                isFirstAppointment: true
                                            };
                                        });
                                    }

                                    return mapAppointment(appt);
                                });

                            return {
                                ...schedule,
                                equipamentSchedules: [{ clinic, date, start: clinicStartHour, end: clinicEndHour }],
                                allocatedTime: getAllocatedTime(
                                    schedule.appointments,
                                    [],
                                    EQUIPMENT,
                                    date,
                                    clinicStartHour,
                                    clinicEndHour
                                ),
                                appointments: apptsPerEquipment
                            };
                        })
                    };
                    dispatch(
                        setCalendarViewMode({
                            viewMode: EQUIPMENT
                        })
                    );
                    dispatch(setIsDateChange(false));
                    dispatch(loadDayScheduleSuccess(schedule));
                })
                .catch(err => {
                    dispatch(calendarFetchError());
                    showError(err);
                })
        );
    };
}

export function removeAppointmentRequest() {
    toastr.success('Appointment successfully removed');
    return { type: actions.REMOVE_APPOINTMENT_REQUEST };
}
export function removeAppointmentSuccess(data) {
    return { type: actions.REMOVE_APPOINTMENT_SUCCESS, payload: data };
}

export function removeAppointment(appointment, removeGroup) {
    return dispatch => {
        dispatch(removeAppointmentRequest());
        trackPromise(
            appointmentApi
                .remove(appointment, removeGroup)
                .then(data => dispatch(removeAppointmentSuccess(data)))
                .catch(showError)
        );
    };
}

export function persistCustomerSuccess(appointment) {
    toastr.success('Client saved successfully.');
    return { type: actions.PERSIST_CUSTOMER_SUCCESS, payload: appointment };
}
export function persistCustomerRequest() {
    return { type: actions.PERSIST_CUSTOMER_REQUEST };
}

export function setDrawerEditingAppointmentData(data) {
    return { type: actions.SET_DRAWER_EDITING_APPOINTMENT_DATA, payload: data };
}

export function persistCustomer(data, file, setLHDCustomer = false, clinic = null) {
    return dispatch => {
        dispatch(persistCustomerRequest());
        return CustomerApi.postCustomer(data)
            .then(async result => {
                const user = result.data;
                if (file) {
                    const url = await uploadCustomerAvatar(user.id, file);
                    user.avatar = url;
                }
                dispatch(
                    persistCustomerSuccess({
                        ...user,
                        noValidate: true,
                        postcode: user.postal
                    })
                );
                return result;
            })
            .then(result => {
                if (setLHDCustomer) {
                    if (clinic) {
                        dispatch(fetchCustomerCourses(result.data.id, clinic));
                    }

                    dispatch(setDrawerEditingAppointmentData({ customer: result.data }));
                }
            })
            .catch(showError);
    };
}

export function searchCustomerSuccess(appointment) {
    return { type: actions.SEARCH_CUSTOMER_SUCCESS, payload: appointment };
}
export function searchCustomerRequest() {
    return { type: actions.SEARCH_CUSTOMER_REQUEST };
}

export function searchCustomers(value = '', rowsPerPage, page, timeEvent, clinic, searching) {
    const pageNumber = page ? 0 : page;
    const filterByLastUsed = value ? false : true;

    return dispatch => {
        dispatch(searchCustomerRequest());
        return CustomerApi.listCustomers(value, rowsPerPage, pageNumber, filterByLastUsed, timeEvent, clinic, searching)
            .then(result => {
                dispatch(searchCustomerSuccess(result));
                return result;
            })
            .catch(showError);
    };
}

export function searchPractitionerSuccess(practitioners) {
    return { type: actions.SEARCH_PRACTITIONER_SUCCESS, payload: practitioners };
}
export function searchPractitionerRequest() {
    return { type: actions.SEARCH_PRACTITIONER_REQUEST };
}

export function searchPractitioners(value) {
    return dispatch => {
        dispatch(searchPractitionerRequest());
        return practitionerApi
            .searchPractitioners(value)
            .then(result => {
                return dispatch(searchPractitionerSuccess(result));
            })
            .catch(showError);
    };
}

export function fetchCategoriesSuccess(appointment) {
    return { type: actions.FETCH_CATEGORIES_SUCCESS, payload: appointment };
}
export function fetchCategoriesRequest() {
    return { type: actions.FETCH_CATEGORIES_REQUEST };
}

export function fetchCategories() {
    return dispatch => {
        dispatch(fetchCategoriesRequest());
        return serviceApi
            .getCategories()
            .then(result => dispatch(fetchCategoriesSuccess(result.data)))
            .catch(showError);
    };
}

export function fetchServicesSuccess(appointment) {
    return { type: actions.FETCH_SERVICES_SUCCESS, payload: appointment };
}
export function fetchServicesRequest() {
    return { type: actions.FETCH_SERVICES_REQUEST };
}

export function fetchServices(value) {
    return dispatch => {
        dispatch(fetchServicesRequest());
        return serviceApi
            .getServices(value)
            .then(result => dispatch(fetchServicesSuccess(result)))
            .catch(showError);
    };
}

export function filterRooms(payload) {
    return { type: actions.FILTER_ROOMS, payload };
}
export function fetchRoomsSuccess(payload) {
    return { type: actions.FETCH_ROOMS_SUCCESS, payload };
}
export function fetchRoomsApptSuccess(payload) {
    return { type: actions.FETCH_ROOMS_APPT_SUCCESS, payload };
}
export function fetchRoomsRequest() {
    return { type: actions.FETCH_ROOMS_REQUEST };
}

export function fetchRooms(clinic) {
    return dispatch => {
        dispatch(fetchRoomsRequest());
        return roomApi
            .getRooms(clinic)
            .then(result => dispatch(fetchRoomsSuccess(result)))
            .catch(err => {
                dispatch(calendarFetchError());
                showError(err);
            });
    };
}

export function fetchAvailableRooms(data) {
    return dispatch => {
        dispatch(fetchRoomsRequest());
        return roomApi
            .getAvailableRoom(data)
            .then(result => dispatch(fetchRoomsSuccess(result)))
            .catch(err => {
                dispatch(calendarFetchError());
                showError(err);
            });
    };
}

export function calendarFetchError() {
    return { type: actions.CALENDAR_FETCH_ERROR };
}

export function login(user) {
    return { type: actions.LOGIN, payload: user };
}

export function logout() {
    return { type: actions.LOGOUT };
}

export function setSelectedDate(date) {
    return { type: actions.SET_SELECTED_DATE, payload: date };
}

export function zoomSet(level) {
    return { type: actions.ZOOM_SET, payload: level };
}
export function zoomIn() {
    return { type: actions.ZOOM_IN };
}
export function zoomOut() {
    return { type: actions.ZOOM_OUT };
}

export function persistPractitionerOrder(newOrder) {
    return { type: actions.PERSIST_PRACTITIONER_LIST_ORDER, payload: newOrder };
}

export function showSearchCustomerModal() {
    return { type: actions.SHOW_SEARCH_CUSTOMER_MODAL };
}
export function hideSearchCustomerModal() {
    return { type: actions.HIDE_SEARCH_CUSTOMER_MODAL };
}

export function showCreateCustomerModal() {
    return { type: actions.SHOW_CREATE_CUSTOMER_MODAL };
}
export function hideCreateCustomerModal() {
    return { type: actions.HIDE_CREATE_CUSTOMER_MODAL };
}

export function showPractitionerReorderModal() {
    return { type: actions.SHOW_PRACTITIONER_REORDER_MODAL };
}
export function hidePractitionerReorderModal() {
    return { type: actions.HIDE_PRACTITIONER_REORDER_MODAL };
}

export function persistAppointmentRequest(appointment) {
    toastr.success(`Appointment successfully ${appointment.id ? 'updated' : 'saved'}`);
    return { type: actions.PERSIST_APPOINTMENT_REQUEST, payload: { appointment } };
}
export function persistAppointmentSuccess() {
    return { type: actions.PERSIST_APPOINTMENT_SUCCESS };
}
export function appointmentError(error) {
    const message = _.get(error, 'response.data.message') || _.get(error, 'message');
    return toastr.error(message);
}

export function showConflictsModal(recurringIssues, type) {
    return { type: actions.SHOW_CONFLICTS_MODAL, payload: { conflicts: recurringIssues, type } };
}

export function hideConflictsModal() {
    return { type: actions.HIDE_CONFLICTS_MODAL };
}
export async function unlinkAppointments(apptIds) {
    await trackPromise(CalendarApi.unlinkAppointments(apptIds));
}

export function persistAppointment(appointment, viewMode) {
    return dispatch => {
        return trackPromise(
            CalendarApi.persistAppointment(appointment)
                .then(response => {
                    if (response.data && response.data.recurringIssues && response.data.recurringIssues.length > 0) {
                        dispatch(showConflictsModal(response.data.recurringIssues, appointment.type));
                    }
                    if (viewMode === ROOMS) {
                        toastr.success(`Appointment successfully ${appointment.id ? 'updated' : 'saved'}`);
                        dispatch(persistAppointmentSuccess());
                        return 'success';
                    }

                    /**
                     * Reset appointment not to send property.
                     * It is used only for clipboard reschedule. Should be false by default.
                     */
                    if (appointment?.notToSend) {
                        appointment.notToSend = false;
                    }

                    dispatch(persistAppointmentRequest(appointment));
                    dispatch(persistAppointmentSuccess());
                    return 'success';
                })
                .catch(error => {
                    appointmentError(error);
                    return 'error';
                })
        );
    };
}

export function persistLeaveSuccess(leave) {
    return { type: actions.PERSIST_LEAVE_SUCCESS, payload: { leave } };
}

export function updateAppointmentNotesRequest(appointment) {
    toastr.success('Note successfully updated');
    return { type: actions.UPDATE_APPOINTMENT_NOTES_REQUEST, payload: { appointment } };
}

export function updateAppointmentNotes(appointment) {
    return dispatch => {
        dispatch(updateAppointmentNotesRequest(appointment));
        return trackPromise(
            AppointmentApi.updateAppointmentNotes(appointment)
                .then(() => dispatch(persistAppointmentSuccess()))
                .catch(error => appointmentError(error))
        );
    };
}

export function setShowZoom(showZoom) {
    return { type: actions.SET_SHOW_ZOOM, payload: showZoom };
}

export function hideCustomerInfo(isHidden) {
    return { type: actions.SET_HIDE_CUSTOMER_INFO, payload: isHidden };
}

export function openRefreshModal() {
    return { type: actions.OPEN_REFRESH_MODAL };
}

export function closeRefreshModal() {
    return { type: actions.CLOSE_REFRESH_MODAL };
}

export function setCalendarViewMode(viewMode) {
    return { type: actions.SET_CALENDAR_VIEW_MODE, payload: viewMode };
}

export function setCalendarData(data) {
    return { type: actions.SET_CALENDAR_DATA, payload: data };
}

export function setDrawerData(data) {
    return { type: actions.SET_DRAWER_DATA, payload: data };
}

export function setIsDateChange(data) {
    return { type: actions.IS_DATE_CHANGE, payload: data };
}

