import React, { useEffect, useRef, useState, useMemo, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

import CalendarNav from './nav/CalendarNav';
import PopupNote from './container/PopupNote';
import CalendarDrawer from './drawer/CalendarDrawer';
import AvailableCourse from './container/AvailableCourse';
import CalendarContainer from './container/CalendarContainer';
import ReorderStaffModal from './reorderStaffModal/ReorderStaffModal';
import PrintAppointmentModal from './container/PrintAppointmentModal';
import AppointmentHistoryModal from '../common/AppointmentHistoryModal';
import SearchCustomerModal from './searchCustomerModal/SearchCustomerModal';
import CreateCustomerModal from '../../collums-components/components/common/CreateCustomerModal/CreateCustomerModal';
import CalendarAppointmentDragLayer from './container/CalendarAppointmentDragLayer';
import ConflictsModal from './conflictsModal/ConflictsModal';

import Moment from 'moment';
import queryString from 'query-string';
import { DndProvider } from 'react-dnd';
import { isTablet } from 'react-device-detect';
import HtmlBackend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import {
    hidePractitionerReorderModal,
    setCalendarData,
    setDrawerData,
    showCreateCustomerModal,
    setIsDateChange,
    persistAppointment,
    loadDayPractitionersSchedule,
    loadDayEquipmentSchedule,
    loadDayRoomSchedule,
    loadPractitionersAvailable,
    persistPractitionerOrder,
    fetchCategories,
    fetchServices,
    fetchRooms,
    searchCustomers,
    searchPractitioners,
    persistCustomer,
    zoomSet,
    setSelectedDate,
    hideSearchCustomerModal,
    hideCreateCustomerModal,
    setShowZoom,
    setCalendarViewMode
} from '../../actions/dayScheduleActions';
import { openPopupModal } from '../../actions/appointmentActions';
import { useQuery } from '../../services/hooks';
import { CHANGE_CLINIC } from '../../actions/actionTypes';
import { EQUIPMENT, ROOMS, STAFF, STAFF_WEEK } from '../../constants/viewModes';
import { PRACTITIONER_ORDER, NOT_VISIBLE_START, CURRENT_CLINIC } from '../../constants/LocalStorage';
import '../../style/Calendar.css';
import ShowJourneyModal from '../common/ShowJourneyModal';
import getClinicHours from './../../services/helpers/getClinicHours';
import { usePageVisibility } from 'react-page-visibility';
import { differenceInSeconds } from 'date-fns';
import {
    getActionPostPopUpSelector,
    getAllSchedulesSelector,
    getApptPopupSelector,
    getApptPopUpTypeSelector,
    getCheckInMetaDataSelector,
    getWeekViewPractitionerSelector
} from '../../customSelectors/appointments';
import {
    getViewModeSelector,
    getIsLeftDrawerOpenSelector,
    getClinicTimesSelector,
    getIsReorderModalOpenSelector
} from '../../customSelectors/calendar';
import defaultRenderCheck from '../../services/helpers/defaultRenderCheck';
import {
    getCreateCustomerModalOpenSelector,
    getCurrentUserSelector,
    getDayScheduleSelector,
    getSelectedDateSelector
} from '../../customSelectors/calendar';

const DndProviderOptions = { enableTouchEvents: true };

function CalendarView() {
    const localStorageClinic = localStorage.getItem(CURRENT_CLINIC);
    const [selectedTimeslotId, setSelectedTimeslotId] = useState('');
    const [selectedClinic, setSelectedClinic] = useState({ id: localStorageClinic });

    const appointmentMove = useRef({});
    const isFirstRender = useRef(true);
    const dayQuery = useQuery('day');
    const history = useHistory();
    const location = useLocation();
    const visibility = usePageVisibility();

    const memoViewMode = useSelector(getViewModeSelector);
    const memoWeekViewPractitioner = useSelector(getWeekViewPractitionerSelector);
    const memoCurrentUser = useSelector(getCurrentUserSelector);
    const memoDaySchedule = useSelector(getDayScheduleSelector);
    const memoSelectedDate = useSelector(getSelectedDateSelector);
    const memoIsCreateCustomerModalOpen = useSelector(getCreateCustomerModalOpenSelector);
    const memoIsLeftDrawerOpen = useSelector(getIsLeftDrawerOpenSelector);
    const memoClinicProps = useSelector(getClinicTimesSelector);

    const memoIsReorderPractitionerModalOpen = useSelector(getIsReorderModalOpenSelector);

    const notes = useSelector(getApptPopupSelector);
    const popupType = useSelector(getApptPopUpTypeSelector);
    const actionPostPopup = useSelector(getActionPostPopUpSelector);
    const { isCheckingIn } = useSelector(getCheckInMetaDataSelector);
    const schedules = useSelector(getAllSchedulesSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (location?.search) {
            const qString = queryString.parse(location?.search ? location.search.replace('?', '') : '');
            if (qString?.clinic) {
                localStorage.setItem(CURRENT_CLINIC, qString.clinic);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatches = useMemo(() => {
        return {
            persistAppointment: appointment => dispatch(persistAppointment(appointment)),
            loadDayPractitionersSchedule: date => dispatch(loadDayPractitionersSchedule(date)),
            loadDayEquipmentSchedule: (date, clinicStartHour, clinicEndHour) =>
                dispatch(loadDayEquipmentSchedule(date, clinicStartHour, clinicEndHour)),
            loadDayRoomSchedule: (date, clinicStartHour, clinicEndHour) =>
                dispatch(loadDayRoomSchedule(date, clinicStartHour, clinicEndHour)),
            loadPractitionersAvailable: (date, clinicStartHour, clinicEndHour) =>
                dispatch(loadPractitionersAvailable(date, clinicStartHour, clinicEndHour)),
            persistPractitionerOrder: newOrder => dispatch(persistPractitionerOrder(newOrder)),
            fetchCategories: () => dispatch(fetchCategories()),
            fetchServices: category => dispatch(fetchServices(category)),
            fetchRooms: category => dispatch(fetchRooms(category)),
            searchCustomers: data => {
                if (typeof data === 'object') {
                    const { value, event, clinic } = data;
                    return dispatch(searchCustomers(value, null, null, event, clinic, true));
                } else return dispatch(searchCustomers(data));
            },
            searchPractitioners: data => dispatch(searchPractitioners(data)),
            persistCustomer: (data, file, clinic) => dispatch(persistCustomer(data, file, true, clinic)),
            zoomSet: zoom => dispatch(zoomSet(zoom)),
            setSelectedDate: date => dispatch(setSelectedDate(date)),
            hideSearchCustomerModal: () => {
                const clientSearchModal = document.querySelector('#modal');
                if (clientSearchModal) {
                    clientSearchModal.style.visibility = 'hidden';
                }
                dispatch(hideSearchCustomerModal());
            },
            showCreateCustomerModal: () => dispatch(showCreateCustomerModal()),
            hideCreateCustomerModal: () => dispatch(hideCreateCustomerModal()),
            hideReorderPractitionerModal: () => dispatch(hidePractitionerReorderModal()),
            setShowZoom: showZoom => dispatch(setShowZoom(showZoom)),
            setCalendarViewMode: viewMode => dispatch(setCalendarViewMode(viewMode))
        };
    }, [dispatch]);

    const onDateChange = useCallback(
        change => {
            dispatch(setIsDateChange(change));
        },
        [dispatch]
    );

    const selectDate = useCallback(
        date => {
            dispatches.setSelectedDate(date);
            onDateChange(true);
        },
        [onDateChange, dispatches]
    );

    const fetchDaySchedule = useCallback(
        ({ viewMode, weekViewPractitioner, setDate } = {}) => {
            const handler = {
                [STAFF]: () => {
                    dispatches.loadDayPractitionersSchedule({
                        date: setDate || memoSelectedDate || Moment(),
                        clinic: selectedClinic,
                        force: true
                    });
                    dispatches.setCalendarViewMode({
                        viewMode: STAFF
                    });
                    if (setDate) selectDate(setDate);
                },
                [STAFF_WEEK]: () => {
                    dispatches.loadDayPractitionersSchedule({
                        weekViewPractitioner: weekViewPractitioner ? weekViewPractitioner : memoWeekViewPractitioner,
                        date: memoSelectedDate || Moment(),
                        clinic: selectedClinic,
                        force: true
                    });
                    dispatches.setCalendarViewMode({
                        viewMode: STAFF_WEEK
                    });
                },
                [EQUIPMENT]: () => {
                    const clinicHours = getClinicHours(selectedClinic, memoSelectedDate);
                    dispatches.loadDayEquipmentSchedule({
                        date: memoSelectedDate,
                        clinicStartHour: clinicHours.start,
                        clinicEndHour: clinicHours.end,
                        clinic: selectedClinic
                    });
                    dispatches.setCalendarViewMode({
                        viewMode: EQUIPMENT
                    });
                },
                [ROOMS]: () => {
                    dispatches.loadDayRoomSchedule({
                        date: memoSelectedDate || Moment(),
                        clinic: selectedClinic
                    });
                    dispatches.loadPractitionersAvailable({
                        date: memoSelectedDate || Moment(),
                        clinic: selectedClinic
                    });
                    dispatches.setCalendarViewMode({
                        viewMode: ROOMS
                    });
                },
                [undefined]: () => {
                    fetchDaySchedule({ viewMode: memoViewMode || STAFF });
                }
            }[viewMode];
            handler();
        },
        [selectDate, dispatches, selectedClinic, memoSelectedDate, memoViewMode, memoWeekViewPractitioner]
    );

    useEffect(() => {
        if (dayQuery && Moment(dayQuery, 'DD-MM-YYYY').isValid()) {
            dispatches.setSelectedDate(Moment(dayQuery, 'DD-MM-YYYY'));
        } else {
            dispatches.setSelectedDate(Moment());
        }
        dispatches.setShowZoom(true);
        dispatches.fetchRooms(selectedClinic.id);
        dispatches.searchPractitioners();

        return () => {
            dispatches.setShowZoom(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatches]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else if (selectedClinic?.accountName) {
            const query = queryString.parse(location.search);
            const newQuery = { ...query, day: memoSelectedDate.format('DD-MM-YYYY') };
            history.push(`/?${queryString.stringify(newQuery)}`);
            fetchDaySchedule();
        }
        //eslint-disable-next-line
    }, [memoSelectedDate, selectedClinic]);

    useEffect(() => {
        if (!visibility) localStorage.setItem(NOT_VISIBLE_START, new Date().toISOString());

        if (visibility) {
            const start = localStorage.getItem(NOT_VISIBLE_START) || new Date();
            const secondsOff = differenceInSeconds(new Date(), new Date(start));

            if (secondsOff > 60) {
                const query = queryString.parse(location.search);
                const newQuery = { ...query, day: memoSelectedDate.format('DD-MM-YYYY') };
                history.push(`/?${queryString.stringify(newQuery)}`);
                fetchDaySchedule();
            }
            return localStorage.removeItem(NOT_VISIBLE_START);
        }

        //eslint-disable-next-line
    }, [visibility, fetchDaySchedule, memoSelectedDate]);

    useEffect(() => {
        const query = queryString.parse(location.search);
        if (query.appointment && query.day === memoSelectedDate.format('DD-MM-YYYY')) {
            const appointment = schedules
                .map(sc => sc.appointments)
                .flat()
                .find(app => app.id === query.appointment);
            if (!appointment) return;
            dispatch(
                setDrawerData({
                    editingAppointment: { ...appointment, practitioners: [appointment.practitioner] },
                    isDrawerOpen: true,
                    isOnCancelMode: query.action === 'cancel',
                    isClipboardVisible: false,
                    drawerAnimation: true
                })
            );
        }
        //eslint-disable-next-line
    }, [memoDaySchedule, dispatch, memoSelectedDate, schedules]);

    const moveStartDate = ({ unit, amount }) => {
        selectDate(memoSelectedDate.clone().add(amount, unit));
    };

    const onAvatarClick = useCallback(
        (weekViewPractitioner, date) => {
            if (memoWeekViewPractitioner) {
                //GO TO STAFF VIEW
                fetchDaySchedule({ viewMode: STAFF, setDate: date });
                dispatch(setCalendarData({ weekViewPractitioner: null, viewMode: STAFF }));
            } else {
                //GO TO STAFF WEEK VIEW
                dispatch(setCalendarData({ weekViewPractitioner, viewMode: STAFF_WEEK }));
                fetchDaySchedule({ viewMode: STAFF_WEEK, weekViewPractitioner });
            }
        },
        [fetchDaySchedule, memoWeekViewPractitioner, dispatch]
    );

    const handlePractitionerReordering = useCallback(
        orderedArray => {
            const order = orderedArray.map(schedule => schedule.practitioner.id);
            let reorderedSchedule = order
                .map(id => schedules.find(schedule => schedule.practitioner.id === id))
                .filter(x => !!x);
            reorderedSchedule = [
                ...reorderedSchedule,
                ...schedules.filter(sch => !order.includes(sch.practitioner.id))
            ];
            localStorage.setItem(PRACTITIONER_ORDER, reorderedSchedule.map(sch => sch.practitioner.id).join(','));

            dispatches.persistPractitionerOrder({ ...memoDaySchedule, schedules: reorderedSchedule });
        },
        [schedules, dispatches, memoDaySchedule]
    );

    const onViewModeChange = useCallback(
        async viewMode => {
            if (ROOMS === viewMode) {
                await dispatches.fetchRooms(selectedClinic.id);
            }
            fetchDaySchedule({ viewMode });
        },
        [fetchDaySchedule, dispatches, selectedClinic.id]
    );

    const navigateToCustomerDetails = async customerId => {
        dispatches.hideSearchCustomerModal();

        history.push(`/customer/${customerId}/general`);
    };

    const setTimeslotId = useCallback(id => {
        setSelectedTimeslotId(id);
    }, []);

    const hideReorder = result => {
        dispatches.hideReorderPractitionerModal();
        if (result) {
            fetchDaySchedule({});
        }
    };

    const getBackendByDevice = useMemo(() => {
        return isTablet ? TouchBackend : HtmlBackend;
    }, []);

    if (!memoCurrentUser) {
        return null;
    }

    return (
        <div id="Calendar-view">
            <DndProvider backend={getBackendByDevice} options={DndProviderOptions}>
                <CalendarNav
                    currentUser={memoCurrentUser}
                    onViewModeChange={onViewModeChange}
                    onDateChange={selectDate}
                    onStartDateChange={moveStartDate}
                    onTodayDateClicked={() => {}}
                    selectedDate={memoSelectedDate}
                    isPractitionerSelected={!!memoWeekViewPractitioner}
                    onChangeClinic={clinic => {
                        dispatch({ type: CHANGE_CLINIC, payload: clinic });
                        setSelectedClinic(clinic);
                    }}
                    setDateChange={onDateChange}
                />
                {memoIsLeftDrawerOpen && (
                    <CalendarDrawer
                        clinicStartHour={memoClinicProps.clinicStartHour}
                        clinicStartTime={memoClinicProps.clinicStartTime}
                        clinicEndTime={memoClinicProps.clinicEndTime}
                        searchCustomers={dispatches.searchCustomers}
                        clinicEndHour={memoClinicProps.clinicEndHour}
                        fetchDaySchedule={fetchDaySchedule}
                        viewMode={memoViewMode}
                        selectedDate={memoSelectedDate}
                        selectDate={selectDate}
                        setSelectedTimeslotId={setTimeslotId}
                        selectedClinic={selectedClinic}
                        showCreateCustomerModal={dispatches.showCreateCustomerModal}
                        selectedTimeslotId={selectedTimeslotId}
                    />
                )}
                <CalendarContainer
                    appointmentMove={appointmentMove}
                    viewMode={memoViewMode}
                    schedules={schedules}
                    date={memoDaySchedule.date}
                    showCreateCustomerModal={dispatches.showCreateCustomerModal}
                    persistPractitionerOrder={dispatches.persistPractitionerOrder}
                    handlePractitionerReordering={handlePractitionerReordering}
                    calendarStartHour={memoClinicProps.calendarStartHour}
                    calendarEndHour={memoClinicProps.calendarEndHour}
                    clinicStartTime={memoClinicProps.clinicStartTime}
                    clinicEndTIme={memoClinicProps.clinicEndTime}
                    clinicStartHour={memoClinicProps.clinicStartHour}
                    clinicWeekTimes={memoClinicProps.clinicWeekTimes}
                    availablePractitioners={memoDaySchedule.availablePractitioners}
                    clinicEndHour={memoClinicProps.clinicEndHour}
                    onAvatarClick={onAvatarClick}
                    fetchDaySchedule={fetchDaySchedule}
                    selectedDate={memoSelectedDate}
                    clinic={selectedClinic}
                    onDateChange={selectDate}
                />

                <SearchCustomerModal
                    hide={dispatches.hideSearchCustomerModal}
                    navigateToCustomerDetails={navigateToCustomerDetails}
                    showCreateCustomerModal={dispatches.showCreateCustomerModal}
                />

                <CreateCustomerModal
                    isCreateCustomerModalOpen={memoIsCreateCustomerModalOpen}
                    hideCreateCustomerModal={dispatches.hideCreateCustomerModal}
                    persistCustomer={dispatches.persistCustomer}
                    reloadCustomerList={dispatches.searchCustomers}
                    clinic={selectedClinic.id}
                />

                <ReorderStaffModal isOpen={memoIsReorderPractitionerModalOpen} hide={result => hideReorder(result)} />

                <CalendarAppointmentDragLayer viewMode={memoViewMode} appointmentMove={appointmentMove} />
                <AppointmentHistoryModal />
                <ShowJourneyModal />
                <PrintAppointmentModal />
                {!!notes.length && !!popupType.length && (
                    <PopupNote
                        notes={notes}
                        isOpen={notes.length > 0}
                        popupType={popupType}
                        postAction={actionPostPopup}
                        handleClose={() => dispatch(openPopupModal([]))}
                    />
                )}
                {isCheckingIn && <AvailableCourse isOpen={isCheckingIn} />}
                <ConflictsModal />
            </DndProvider>
        </div>
    );
}

export default memo(withRouter(CalendarView), defaultRenderCheck);
