import React, { useEffect, useRef, useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import Moment from 'moment';
import { Typography, IconButton, Button, withStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { AddBlue } from '../../../../assets/icons';
import { TreatmentNoteStyles } from './styles';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import DeleteTemplatesModal from '../../../../collums-components/components/common/DeleteTemplatesModal';
import CancelContinueModal from '../../../../collums-components/components/common/CancelContinueModal';
import { toastr } from 'react-redux-toastr';
import { isEqual } from 'lodash';
import CustomAutoComplete from '../../forms/AutoComplete/index';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { TREATMENT_LIST, TREATMENT_NOTE_TYPES } from '../../../../collums-constants';
import resetTreatmentType from './../../../../services/helpers/resetTreatmentType';
import customDropdownSearch from '../../../../collums-components/helpers/customDropdownSearch';
import PopoverOptions from './PopoverOptions';
import SaveTemplate from '../SaveTemplate';
import TreatmentNoteApi from '../../../../collums-components/api/TreatmentNoteApi';
import moment from 'moment/moment';
import { useHistory } from 'react-router-dom';
import TreatmentNotesHistory from './TreatmentNotesHistory';
import TinyMce from '../../../../collums-components/components/common/TinyMce/TinyMce';
import LoadingScreen from '../../../../collums-components/components/common/loadingScreen';

const TreatmentNoteForm = ({
    classes,
    window,
    formRef,
    customer,
    closeTreatmentNote,
    saveTreatmentNote,
    services,
    setShowUploadPhotosModal,
    setShowTreatmentPhotosModal,
    loadTemplates,
    templates,
    defaultForm,
    changeNoteType,
    getDailyData,
    canvas,
    setUploadedDocs,
    setShowWarningModal,
    isCurrentlyEditingNote,
    setIsCurrentlyEditingNote,
    setIsFromDesignTab,
    treatmentNote
}) => {
    const redirectTo = useRef();
    const [warningModalTarget, setWarningModalTarget] = useState(false);
    const buttonsClasses = makeStyles(modalsButtonStyles)();
    const [form, setForm] = useState(formRef.current);
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
    const [isOpenDeleteTemplateModal, setIsOpenDeleteTemplateModal] = useState(false);
    const [serviceToChange, setServiceToChange] = useState(null);
    const momentRef = Moment(formRef.current.createdAt);
    const hasChangedService = useRef(false);
    const isReducedView = useMediaQuery('(max-width:1366px)');
    const editorRef = useRef();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const defaultConfig = {
        selector: '.tinymce',
        plugins: ['lists', 'code', 'image', 'link', 'media', 'table'],
        toolbar:
            'undo redo | ' +
            'styles | ' +
            'bold italic strikethrough forecolor backcolor | ' +
            'link image media | ' +
            'table | ' +
            'indent align bullist numlist | ' +
            'code removeformat',
        table_toolbar:
            'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        toolbar_mode: 'wrap',
        fixed_toolbar_container: '.toolbar',
        menubar: true,
        inline: false,
        auto_focus: 'editor-1',
        object_resizing: false,
        placeholder: 'Write here...',
        images_file_types: 'jpeg,jpg,png,gif',
        browser_spellcheck: true,
        automatic_uploads: true,
        file_picker_types: 'image,file',
        file_picker_callback: function(cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : '*/*');

            input.onchange = function() {
                setIsLoading(true);
                const file = this.files[0];
                const reader = new FileReader();

                reader.onload = function() {
                    const dataURL = reader.result;
                    fetch(JSON.parse(localStorage.getItem('linkCache')).backendUrl + '/email-attachment-upload', {
                        method: 'POST',
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                            Accept: 'application/json'
                        },
                        body: JSON.stringify({ dataURL, fileName: file.name })
                    })
                        .then(response => {
                            if (response.status === 413) {
                                throw new Error('File exceeds max size 50MB');
                            }
                            return response.json();
                        })
                        .then(data => {
                            cb(data.imageUrl, { alt: file.name, text: file.name });
                            setIsLoading(false);
                        })
                        .catch(e => {
                            setIsLoading(false);
                            if (e.message) {
                                toastr.error(e.message);
                            } else {
                                toastr.error('Error uploading file. Please try again.');
                            }
                        });
                };

                reader.readAsDataURL(file);
            };

            input.click();
        }
    };

    const handleClick = e => {
        const getTarget = e => {
            const eTargetTag = e.target.tagName.toLowerCase() || null;
            if (!eTargetTag) return null;

            switch (eTargetTag) {
                case 'a':
                    // link element
                    return e.target;
                case 'span': {
                    if (
                        // click span element on sub tab menu
                        e.target.parentNode.tagName === 'A' &&
                        e.target.parentNode.classList.contains('medical-sub-tab')
                    ) {
                        return e.target.parentNode;
                    } else {
                        return e.target;
                    }
                }
                case 'img': {
                    if (
                        // click image icon on the sidebar
                        e.target.closest('#sidebarContainer') &&
                        e.target?.parentNode?.parentNode &&
                        e.target?.parentNode?.parentNode.tagName === 'A'
                    ) {
                        return e.target.parentNode.parentNode;
                    } else {
                        return e.target;
                    }
                }
                default:
                    // all other elements
                    return e.target;
            }
        };

        if (
            e.detail !== 1000 &&
            (e.target.closest('#sidebarContainer') ||
                e.target.closest('header') ||
                e.target.closest('.customer-header'))
        ) {
            if (!isEqual(JSON.stringify(formRef.current || {}), JSON.stringify(defaultForm.current || {}))) {
                e.stopPropagation();
                e.preventDefault();

                setWarningModalTarget(getTarget(e));
            } else {
                setWarningModalTarget(false);
            }
        }
        if (e.detail === 1000 && e.target.closest('.customer-header')) {
            history.push(`/?day=${moment(new Date()).format('DD-MM-YYYY')}`);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const isTreatmentSelected = form.service && form.service.id !== '-' && form.service.defaultTreatmentRecord;
        const newNoteType = isTreatmentSelected ? TREATMENT_NOTE_TYPES.TREATMENT : TREATMENT_NOTE_TYPES.MEDICAL;
        const hasNoteTypeChanged = newNoteType !== formRef.current.type;

        const newTreatmentType = (() => {
            if (form.service?.defaultTreatmentRecord) {
                return form.service.defaultTreatmentRecord;
            }
            return undefined;
        })();

        const hasTreatmentTypeChanged = formRef.current.treatmentType !== newTreatmentType;

        formRef.current = form;
        if ((hasNoteTypeChanged || hasTreatmentTypeChanged) && hasChangedService.current) {
            hasChangedService.current = false;
            if (form.service) {
                formRef.current = resetTreatmentType(newTreatmentType, formRef.current, canvas, getDailyData);
            }

            changeNoteType(isTreatmentSelected);
        }
    }, [form]); //eslint-disable-line

    const serviceList = useMemo(() => {
        const treatmentList = TREATMENT_LIST.map(name => ({ id: '-', name }));
        return [...treatmentList, ...(services || [])];
    }, [services]);

    const updateTreatmentNote = (note = '') => {
        setForm({ ...formRef.current, note });
    };

    const serviceInput = (() => {
        return (
            <>
                <CustomAutoComplete
                    id="service"
                    className={window[0]?.isClosed ? classes.shortAutocomplete : classes.maxAutocomplete}
                    classes={{ listbox: classes.listbox }}
                    style={{ maxWidth: '30%' }}
                    options={serviceList}
                    disabled={form.lockEdit}
                    getOptionLabel={option => option.name}
                    value={form.service}
                    onChange={(_, service) => {
                        if (
                            isCurrentlyEditingNote &&
                            service.defaultTreatmentRecord !== formRef.current.treatmentType
                        ) {
                            setServiceToChange({ ...formRef.current, service });
                        } else {
                            hasChangedService.current = true;
                            setForm({ ...formRef.current, service });
                        }
                    }}
                    fullWidth
                    filterOptions={customDropdownSearch}
                />
                <div>
                    <IconButton
                        className={classes.iconButton}
                        onClick={e => {
                            setAnchorEl(e.currentTarget);
                            setShowOptionsMenu(true);
                        }}
                    >
                        <AddBlue variant="large" />
                    </IconButton>
                </div>
            </>
        );
    })();

    const saveAndCloseButtons = (() => {
        return (
            <>
                <div className={classes.container} style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Typography className={classes.defaultFont} style={{ fontWeight: 500 }}>
                        {momentRef.format('DD/MM/YYYY')}
                    </Typography>
                    <Button
                        onClick={() => {
                            if (
                                isEqual(
                                    JSON.stringify(formRef.current || {}),
                                    JSON.stringify(defaultForm.current || {})
                                )
                            ) {
                                closeTreatmentNote();
                                return;
                            }
                            setShowWarningModal(true);
                        }}
                        className={`${buttonsClasses.cancelButton} ${classes.marginRight} ${classes.defaultFont}`}
                    >
                        Close
                    </Button>
                    <Button
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={saveTreatmentNote}
                        className={`${buttonsClasses.confirmButton} ${classes.noMargin} ${classes.defaultFont}`}
                    >
                        Save & Exit
                    </Button>
                </div>
            </>
        );
    })();

    return (
        <div style={{ padding: 24, display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {isReducedView ? (
                <div className={classes.container}>
                    {serviceInput}
                    {saveAndCloseButtons}
                </div>
            ) : (
                <>
                    <div className={classes.container}>{serviceInput}</div>
                    <div className={classes.container}>{saveAndCloseButtons}</div>
                </>
            )}
            <TreatmentNotesHistory treatmentNote={treatmentNote} />
            <div className={classes.marginTop}>
                <TinyMce
                    value={form.note || ''}
                    onChange={updateTreatmentNote}
                    ref={editorRef}
                    init={{
                        ...defaultConfig,
                        plugins: ['lists', 'code'],
                        toolbar: 'bold italic strikethrough forecolor backcolor | bullist numlist',
                        menubar: false
                    }}
                />
                {isLoading && <LoadingScreen />}
            </div>
            <div className={classes.flexContainer} style={{ gap: '12px' }}>
                <CustomAutoComplete
                    id="note-templates"
                    label=""
                    className={
                        window[0]?.isClosed
                            ? `${classes.shortAutocomplete} ${classes.shortTemplateAutocomplete}`
                            : classes.maxAutocomplete
                    }
                    style={{ maxWidth: '30%' }}
                    options={templates.notes || []}
                    getOptionLabel={option => option.name}
                    onChange={(_, template) => {
                        if (template) {
                            if (editorRef.current) {
                                editorRef.current.value = template.note;
                            }
                            setForm({ ...formRef.current, note: template.note });
                        }
                    }}
                    fullWidth
                    placeholder="Text template"
                />
                <Button
                    onClick={() => {
                        if (!form.note) return toastr.error('Missing note');
                        setIsTemplateModalVisible(true);
                    }}
                    className={`${classes.saveTemplate} ${classes.defaultFont}`}
                >
                    Save new template
                </Button>
                {(templates.notes || []).length > 0 && (
                    <IconButton
                        className={`${classes.deleteIcon}`}
                        onClick={() => {
                            if (templates.notes?.length) {
                                setIsOpenDeleteTemplateModal(true);
                            }
                        }}
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                )}
            </div>
            <PopoverOptions
                showOptionsMenu={showOptionsMenu}
                setShowOptionsMenu={setShowOptionsMenu}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setShowUploadPhotosModal={setShowUploadPhotosModal}
                setShowTreatmentPhotosModal={setShowTreatmentPhotosModal}
                customer={customer}
                setUploadedDocs={setUploadedDocs}
                RequireDocumentAndPrescription={true}
                setIsFromDesignTab={setIsFromDesignTab}
                formRef={formRef}
                form={form}
                setForm={setForm}
                classes={classes}
            />
            {isTemplateModalVisible && (
                <SaveTemplate
                    closeModal={() => setIsTemplateModalVisible(false)}
                    content={{
                        note: form.note
                    }}
                    loadTemplates={loadTemplates}
                    type="note"
                />
            )}
            {serviceToChange && (
                <CancelContinueModal
                    id="warning-modal"
                    title={'Data loss warning'}
                    setOpen={() => {
                        setServiceToChange(null);
                    }}
                    content={
                        <Typography>
                            You are changing the service to one with a different template and will result in losing the
                            data you have inputted
                            <br />
                            Are you sure you want to continue?
                        </Typography>
                    }
                    onContinue={() => {
                        hasChangedService.current = true;
                        setForm(serviceToChange);
                        setIsCurrentlyEditingNote(false);
                        setServiceToChange(null);
                    }}
                    onCancel={() => {
                        hasChangedService.current = false;
                        setForm({ ...formRef.current, service: { ...formRef.current.service, refresh: true } });
                        setServiceToChange(null);
                    }}
                />
            )}
            {warningModalTarget && (
                <CancelContinueModal
                    id="warning-modal2"
                    setOpen={() => {
                        setWarningModalTarget(false);
                        redirectTo.current = undefined;
                    }}
                    title={'Warning'}
                    contentText={'Your notes will not be saved. Are you sure you want to continue?'}
                    onContinue={() => {
                        const clickEvent = new MouseEvent('click', { detail: 1000 });
                        warningModalTarget.dispatchEvent(clickEvent);
                        setWarningModalTarget(false);
                    }}
                />
            )}
            <DeleteTemplatesModal
                templates={templates.notes || []}
                isOpen={isOpenDeleteTemplateModal}
                setIsOpen={setIsOpenDeleteTemplateModal}
                deleteAction={TreatmentNoteApi.deleteNotesTemplate}
                successMessage="Medical Note Text Template(s) successfully deleted"
                refreshTemplates={loadTemplates}
            />
        </div>
    );
};

TreatmentNoteForm.propTypes = {
    classes: PropTypes.object.isRequired,
    window: PropTypes.array,
    formRef: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    closeTreatmentNote: PropTypes.func.isRequired,
    saveTreatmentNote: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    setUploadedDocs: PropTypes.func.isRequired,
    loadTemplates: PropTypes.func.isRequired,
    templates: PropTypes.object.isRequired,
    defaultForm: PropTypes.object.isRequired,
    changeNoteType: PropTypes.string.isRequired,
    getDailyData: PropTypes.func.isRequired,
    canvas: PropTypes.object.isRequired,
    setShowUploadPhotosModal: PropTypes.func.isRequired,
    isCurrentlyEditingNote: PropTypes.func.isRequired,
    setIsCurrentlyEditingNote: PropTypes.func.isRequired,
    setShowTreatmentPhotosModal: PropTypes.func.isRequired,
    setShowWarningModal: PropTypes.func.isRequired,
    setIsFromDesignTab: PropTypes.func,
    treatmentNote: PropTypes.object
};

export default withStyles(TreatmentNoteStyles)(TreatmentNoteForm);
