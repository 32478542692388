import { CELL_HEIGHT, DRAWER_WIDTH, NAVBAR_HEIGHT, PRACTITIONER_NOTES_LINES } from '../../../constants/calendar';
import theme from '../../theme';

export const headerStyles = () => ({
    cell: {
        position: 'relative',
        padding: '0px',
        backgroundColor: '#fff',
        border: '1px solid #9fa9b2',
        cursor: 'grab'
    },
    emptyGrid: {
        marginTop: 4,
        maxWidth: 75,
        flexGrow: 1
    },
    previousStaffButton: {
        position: 'absolute',
        fontSize: 16,
        top: 0,
        left: -27,
        padding: 0,
        minWidth: 27,
        zIndex: 10
    },
    nextStaffButton: {
        position: 'absolute',
        fontSize: 16,
        top: 0,
        right: -27,
        padding: 0,
        minWidth: 27,
        zIndex: 10
    },
    content: {
        padding: 10,
        paddingBottom: 0
    },
    noStaffHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        '& p': {
            textAlign: 'center',
            width: '50%',
            fontSize: 14,
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    goToScheduleButton: {
        background: '#f5473e',
        borderColor: '#f5473e',
        '&:hover': {
            backgroundColor: '#f43026'
        },
        '& a': {
            color: 'white',
            textTransform: 'none',
            lineHeight: 1.5,
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 500,
            fontSize: '0.6rem',
            textDecoration: 'none',
            [theme.breakpoints.up('ipadPro')]: { fontSize: '0.7rem' },
            [theme.breakpoints.up('desktop')]: { fontSize: '0.8rem' }
        }
    }
});

export const containerStyles = () => ({
    header: {
        display: 'flex',
        top: NAVBAR_HEIGHT,
        position: 'fixed',
        right: 0,
        left: 0,
        marginRight: 80,
        marginTop: 20,
        zIndex: 10
    },
    disabledButton: {
        color: 'rgba(0, 0, 0, 0.38)'
    },
    container: {
        display: 'flex',
        bottom: 0,
        position: 'fixed',
        right: 0,
        left: 0,
        overflowY: 'overlay',
        overflowX: 'hidden',
        marginRight: 80,
        marginTop: 20,
        '&::-webkit-scrollbar': {
            width: 8,
            height: 2
        },
        '&::-webkit-scrollbar-button': {
            width: 0,
            height: 0
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c4c4c4',
            border: '0px none #ffffff',
            borderRadius: 50
        },
        '&::-webkit-scrollbar-track': {
            background: '#e5e5e5',
            border: '0px none #ffffff',
            borderRadius: 50,
            visibility: 'hidden'
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent'
        }
    },
    content: {
        padding: 10,
        paddingTop: 0
    },
    option: {
        fontSize: 12
    },
    openedDrawer: {
        marginLeft: `${DRAWER_WIDTH}px`
    },
    timeLabels: {
        color: '#7b7b7b',
        maxWidth: 75,
        padding: '0 !important'
    },
    calendarLabel: {
        '& .MuiListItemText-root': {
            marginTop: 2
        },
        height: CELL_HEIGHT,
        padding: 0,
        textAlign: 'right'
    },
    firstCalendarLabel: {
        '& .MuiListItemText-root': {
            marginTop: 5
        },
        position: 'absolute',
        top: 14,
        left: 30
    },
    calendarLabelPrimary: {
        '& span': {
            fontSize: 13.5,
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 500
        }
    },
    calendarLabelSpacer: {
        height: 36
    },
    calendarLabelTextPrimary: {
        fontWeight: 500
    },
    hide: {
        display: 'none'
    },
    appointmentMenu: {
        backgroundColor: '#A9A9A9',
        marginTop: -10,
        padding: 5,
        paddingLeft: 17
    },
    generateCSV: {
        fontSize: 14,
        color: 'white',
        fontWeight: 500,
        borderRadius: 0,
        textTransform: 'none',
        backgroundColor: '#c00076',
        '&:hover': {
            backgroundColor: '#c00076'
        }
    },
    pdfList: {
        padding: '0 5%',
        width: '100%',
        overflow: 'hidden'
    },
    icons: {
        width: '60px',
        height: '60px'
    },
    table: {
        border: 'solid black',
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: 16
    },
    pdfHeader: {
        margin: '2% 0',
        width: '100%',
        fontSize: '2em'
    },
    pdfTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500
    },
    dateLabel: {
        fontWeight: 500
    },
    tableHeaderCell: {
        textAlign: 'start'
    },
    tableHeader: {
        backgroundColor: '#919191'
    },
    orderTableDataWhite: {
        backgroundColor: 'white'
    },
    orderTableDataGrey: {
        backgroundColor: '#c2c2c2'
    },
    menuContainer: {
        '& li': {
            fontSize: '14px',
            padding: '8px 16px'
        }
    }
});

export const columnStyles = () => ({
    draggedAppointment: {
        position: 'fixed',
        pointerEvents: 'none !important',
        zIndex: 10000,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    },
    tooltipHead: {
        padding: '7px 8px',
        fontWeight: 500,
        fontSize: '1.1em'
    },
    tooltipBody: {
        padding: '8px 8px',
        backgroundColor: 'white',
        color: 'black',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px'
    },
    patient: {
        fontSize: '1.3em'
    },
    room: {
        display: 'inline-block',
        backgroundColor: theme.palette.black.main,
        color: 'white',
        fontWeight: 500,
        padding: '1px 3px',
        margin: '3px 0px'
    },
    nowrap: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginTop: '3px'
    },
    nowrapText: {
        whiteSpace: 'normal'
    },
    ghostlyLabel: {
        color: '#444'
    },
    wrapper: {
        backgroundColor: '#fff',
        border: '1px solid #9fa9b2',
        borderTop: 0,
        position: 'relative'
    },
    calendarSheet: {
        height: CELL_HEIGHT,
        padding: 2,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'pink',
            '& .calendar-sheet-fragment': {
                backgroundColor: 'pink !important'
            }
        },
        overflow: 'hidden'
    },
    scheduleFragment: {
        backgroundColor: 'white'
    },
    calendarSolidBorder: {
        borderBottom: '1px solid #9fa9b2'
    },
    calendarDotBorder: {
        borderBottom: '1px dashed #cdd6df'
    },
    calendarSheetContent: {
        position: 'absolute',
        top: 1,
        left: 2,
        textTransform: 'capitalize',
        margin: '0.5rem'
    },
    // used when clinic is closed
    calendarSheetGrey: {
        backgroundColor: '#d8e1ea'
    },
    // used when practitioner its not working
    calendarSheetDarkerGrey: {
        backgroundColor: '#acb4bb'
    },
    calendarSheetOverlap: {
        width: '100%',
        top: 0,
        position: 'absolute !important',
        overflow: 'hidden',
        borderBottom: 'solid 2px rgba(0, 0, 0, 0)',
        zIndex: 10
    },
    droppable: {
        backgroundColor: '#b2d6f5',
        opacity: 0.7,
        outline: 'rgb(170, 172, 234, 0.9) dashed 3px'
    },
    htmlTooltip: {
        display: 'flex',
        flexFlow: 'column nowrap',
        height: '100%',
        width: '100%',
        margin: '0px',
        padding: '0px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '5px'
    },
    appointment: {
        backgroundColor: theme.palette.appointment,
        borderColor: theme.palette.appointment,
        '&:hover': {
            backgroundColor: theme.palette.appointment
        }
    },
    appointmentEdit: {
        borderColor: theme.palette.editAppointment,
        '&:hover': {
            borderColor: theme.palette.editAppointment
        }
    },
    appointmentRunningLate: {
        backgroundColor: theme.palette.red.main,
        borderColor: theme.palette.red.main,
        '&:hover': {
            backgroundColor: theme.palette.red.main
        }
    },
    appointmentCheckedIn: {
        backgroundColor: theme.palette.yellow.main,
        borderColor: theme.palette.yellow.main,
        '&:hover': {
            backgroundColor: theme.palette.yellow.main
        }
    },
    appointmentCheckedOut: {
        backgroundColor: theme.palette.gray.light,
        borderColor: theme.palette.gray.light,
        '&:hover': {
            backgroundColor: theme.palette.gray.light
        }
    },
    appointmentCheckedOutNotPaid: {
        backgroundColor: theme.palette.leave,
        borderColor: theme.palette.leave,
        '&:hover': {
            backgroundColor: theme.palette.leave
        }
    },
    courseIcon: {
        display: 'inline-block',
        '& img': {
            paddingTop: 4
        }
    },
    appointmentNoShow: {
        backgroundColor: theme.palette.gray.light,
        borderColor: theme.palette.gray.light,
        '&:hover': {
            backgroundColor: theme.palette.gray.light
        }
    },
    appointmentDNA: {
        backgroundColor: theme.palette.leave,
        borderColor: theme.palette.leave,
        '&:hover': {
            backgroundColor: theme.palette.leave
        }
    },
    whiteText: {
        color: theme.palette.white.main
    },
    blackText: {
        color: theme.palette.black.main
    },
    darkGreyText: {
        color: theme.palette.gray.bold
    },
    break: {
        backgroundColor: theme.palette.break,
        borderColor: theme.palette.break,
        '&:hover': {
            backgroundColor: theme.palette.break
        }
    },
    block: {
        backgroundColor: theme.palette.block,
        borderColor: theme.palette.block,
        '&:hover': {
            backgroundColor: theme.palette.block
        }
    },
    leave: {
        backgroundColor: theme.palette.leave,
        borderColor: theme.palette.leave,
        '&:hover': {
            backgroundColor: theme.palette.leave
        }
    },
    textPatient: {
        fontSize: '0.8rem',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        'white-space': 'nowrap',
        overflow: 'hidden',
        pointerEvents: 'none'
    },
    textService: {
        fontSize: '0.7rem',
        fontFamily: 'Roboto, Arial, Sans-serif',
        whiteSpace: 'normal',
        wordBreak: 'break-all',
        overflow: 'hidden'
    },
    textRoom: { fontSize: '0.7rem', fontFamily: 'Roboto, Arial, Sans-serif', 'white-space': 'nowrap' },
    textServiceAndRoom: {
        fontSize: '0.7rem',
        fontFamily: 'Roboto, Arial, Sans-serif',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        marginRight: 12,
        overflow: 'hidden'
    },
    textNote: { overflow: 'hidden', 'white-space': 'wrap' },
    resizingShape: {
        borderRadius: '50%',
        backgroundColor: 'white',
        width: '1rem',
        height: '1rem'
    },
    notes: {
        position: 'absolute',
        left: 0,
        right: 0,
        padding: 5,
        paddingBottom: 0,
        maxHeight: 72,
        marginBottom: 5,
        overflow: 'hidden',
        cursor: 'pointer',
        display: '-webkit-box',
        '-webkit-line-clamp': PRACTITIONER_NOTES_LINES,
        '-webkit-box-orient': 'vertical'
    },
    paddingBottomHack: {
        position: 'relative',
        backgroundColor: '#ffffff',
        height: 7
    },
    noteText: {
        lineHeight: 1,
        fontSize: 12
    },
    pointerEventsNone: { pointerEvents: 'none !important' },
    handle: {
        pointerEvents: 'none !important',
        width: '15px',
        height: '15px',
        border: '0.15rem solid white',
        borderRadius: '50%',
        position: 'absolute',
        margin: '0 auto',
        left: '0px',
        right: '0px'
    },
    buttonBorder: {
        borderRadius: '2px',
        boxShadow: '3 13px 9px 9px #777',
        height: '100%',
        width: '100%'
    },
    topHandle: {
        top: '-8px'
    },
    update: {
        backgroundColor: '#373536',
        borderRadius: 0
    },
    repeatIcon: {
        position: 'absolute',
        top: 7,
        right: '12%',
        '& .MuiIcon-root': {
            width: 12,
            height: 12
        }
    },
    whiteToBlackIcon: {
        '& .MuiIcon-root': {
            '& img': {
                filter: 'invert(100%)'
            }
        }
    }
});

export const practitionerStyles = () => ({
    staff: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        height: '100%',
        alignItems: 'stretch',
        overflow: 'hidden',
        justifyContent: 'center',
        padding: '5px',
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    dayLabel: {
        textAlign: 'center',
        flexWrap: 'nowrap'
    },
    content: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignContent: 'stretch',
        height: 'min-content'
    },
    contentCenter: {
        display: 'flex',
        flex: '3 1 auto',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    avatar: {
        borderRadius: '50%',
        width: 54,
        height: 54,
        cursor: 'pointer'
    },
    contentLeft: {
        display: 'flex',
        flex: '1 1 0px'
    },
    contentRight: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        flex: '1 1 0px'
    },
    menuButton: {
        display: 'flex',
        width: '30px',
        height: '30px',
        justifyContent: 'center',
        alignContent: 'center',
        cursor: 'pointer'
    },
    percentage: {
        fontWeight: 500,
        fontSize: 12
    },
    staffLabel: {
        marginTop: 4,
        fontSize: 12,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textAlign: 'center'
    },
    droppable: {
        opacity: 0.5,
        border: '1px dashed black',
        cursor: 'grabbing'
    },
    staffBubble: {
        fontSize: '12px'
    }
});

export const timeDividerStyles = () => ({
    container: {
        pointerEvents: 'none !important',
        position: 'absolute',
        width: '100%',
        paddingLeft: 52,
        paddingRight: 8,
        zIndex: '2000'
    },
    time: {
        position: 'absolute',
        fontSize: 13.5,
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500,
        top: -10,
        left: 18,
        color: 'rgba(255,0,0,0.5)',
        minWidth: 30,
        textAlign: 'right',
        width: 36
    },
    divider: { backgroundColor: 'red', opacity: 0.5, marginLeft: 7 }
});

export const sharedStyle = theme => ({
    calendarContainer: {
        // marginTop: theme.spacing(2)
    },
    eventContainer: {
        cursor: 'pointer',
        borderRadius: 3,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '2px 2px 0px 2px'
    },
    modeButton: {
        padding: 0,
        '& .material-icons': {
            width: 48,
            height: 48
        }
    }
});

export const editWorkingHoursModalStyles = () => ({
    noVerticalPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    noTopMargin: {
        '& .MuiFormControl-marginDense': {
            marginTop: 0
        }
    },
    card: {
        width: 250,
        margin: '.2rem',
        padding: '.4rem .8rem'
    },
    cardHeader: {
        textAlign: 'center',
        fontSize: '1rem'
    },
    inputColumn: {
        textAlign: 'right'
    },
    modalTitle: {
        margin: '.4rem',
        fontSize: '1rem'
    },
    textModal: {
        fontSize: '.9rem'
    }
});

export const availableCourse = theme => ({
    regularButtonText: {
        color: theme.palette.white.main,
        textTransform: 'none'
    },
    regularButton: {
        backgroundColor: theme.palette.gray.main,
        borderRadius: 0,
        height: 44,
        margin: '0px 10px 10px 0px',
        padding: '6px 14px',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        },
        '& p': {
            fontSize: 14
        }
    },
    saveButton: {
        backgroundColor: theme.palette.wine.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover
        }
    },
    cancelButton: {
        marginLeft: '8px',
        borderColor: theme.palette.lightGrey.main,
        backgroundColor: theme.palette.white.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)'
        }
    },
    textButton: {
        color: theme.palette.lightGrey.main,
        textTransform: 'none',
        opacity: 1
    }
});
export const tooltipClass = () => ({
    tooltip: {
        display: 'flex',
        flexFlow: 'column nowrap',
        height: '100%',
        width: '100%',
        margin: '0px',
        padding: '0px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: props => props.borderColor,
        borderRadius: '5px'
    }
});
