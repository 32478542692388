import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TextField,
    Button,
    Menu,
    MenuItem
} from '@material-ui/core';
import Moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import invoiceApi from '../../../api/InvoiceApi';
import AuthApi from '../../../api/AuthApi';
import { useReactToPrint } from 'react-to-print';

import practitionerApi from '../../../api/PractitionerApi';
import { getClinic } from '../../../api/ClinicApi';
import { INVOICE_PAYMENT_STATUS, INVOICE_ORIGIN, INVOICE_PAYMENT_TYPES } from '../../../../collums-constants/index';
import { InvoiceModalStyles } from './styles';
import { getCurrencySymbol, toLocaleString } from '../../../helpers/index';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
    roundTwoDecimals,
    getTotalsFromInvoiceItems,
    calculateTaxValue
} from '../../../../collums-constants/utils/index';
import LoadingScreen from '../loadingScreen';
import CancelContinueModal from '../CancelContinueModal';
import InvoiceApi from '../../../api/InvoiceApi';
import OrganisationApi from '../../../api/organizationApi';
import _ from 'lodash';
import EditableCell from './EditableCell';
import PaymentLinkDialog from './PaymentLinkDialog';
import XeroApi from '../../../../api/xeroApi';
import { DateTimePicker } from '@material-ui/pickers';

const itemsHeader = [
    { name: 'Sold By' },
    { name: 'Item' },
    { name: '#' },
    { name: 'Discount', numeric: true },
    { name: 'Net Price', numeric: true },
    { name: 'Tax', numeric: true },
    { name: 'Total', numeric: true }
];

const changeLogHeader = [{ name: 'Date' }, { name: 'By' }, { name: 'Note' }];

const paymentsHeader = [
    { name: 'Date/Item' },
    { name: 'Taken By' },
    { name: 'Method' },
    { name: 'Amount', numeric: true }
];

const blockedEditPaymentMethods = [
    INVOICE_PAYMENT_TYPES.CREDIT_CARD,
    INVOICE_PAYMENT_TYPES.CREDIT_CARD_TERMINAL,
    INVOICE_PAYMENT_TYPES.ACCOUNT_BALANCE,
    INVOICE_PAYMENT_TYPES.GIFTCARD
];

const InvoiceModal = ({ classes, invoice, closeInvoiceModal, loadInvoice, source, listUpdate, openRefundModal }) => {
    const [invoiceData, setInvoiceData] = useState({});
    const [invoiceNote, setInvoiceNote] = useState('');
    const [newInvoiceNote, setNewInvoiceNote] = useState('');
    const [practitionerList, setPractitionerList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [newValues, setNewValues] = useState({});
    const [activePractitioners, setActivePractitioners] = useState([]);
    const [voidPopupOpen, setVoidPopupOpen] = useState(false);
    const [voidOsPopupOpen, setVoidOsPopupOpen] = useState(false);
    const [referenceInvoice, setReferenceInvoice] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [modifiedsLogModel, setModifiedsLogModel] = useState(false);
    const [paymentLinkModel, setPaymentLinkModel] = useState(false);
    const [isXeroConnected, setIsXeroConnected] = useState(false);
    const componentRef = useRef();
    const [myClinics, setMyClinics] = useState([]);
    const [currentClinic, setCurrentClinic] = useState(null);
    const [unpaidPaymentLink, setUnpaidPaymentLink] = useState(false);

    // payment table states
    const [editPaymentAmountId, setEditPaymentAmountId] = useState(null);
    const [editPaymentMethodId, setEditPaymentMethodId] = useState(null);
    const [editPaymentDateId, setEditPaymentDateId] = useState(null);
    const [editSoldByItemId, setEditSoldByItemId] = useState(null);
    const [editTotalItemId, setEditTotalItemId] = useState(null);
    const [paymentOptions, setPaymentOptions] = useState([]);

    const [disabledButton, setDisabledButton] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const isFirstRender = useRef(true);

    const isRefundModalOpen = useSelector(state => state.invoice?.isInvoiceRefundModalOpen);

    const clearEditableFieldsState = () => {
        setEditPaymentAmountId(null);
        setEditPaymentMethodId(null);
        setEditTotalItemId(null);
        setEditSoldByItemId(null);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }

        OrganisationApi.getOrg().then(res => {
            const options = res.paymentTypes
                .filter(paymentType => paymentType.active && !blockedEditPaymentMethods.includes(paymentType.name))
                .map(paymentType => ({
                    name: paymentType.name,
                    label: paymentType.label
                }));

            setPaymentOptions(options);
            setIsLoading(false);
        });

        XeroApi.isXeroActive().then(res => {
            setIsXeroConnected('isxeroactive', res?.data?.isConnected);
        });
    }, []);

    useEffect(() => {
        // event called on refund modal close.
        if (!isFirstRender.current && !isRefundModalOpen) {
            reload(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefundModalOpen]);

    const reload = async (reset = false) => {
        if (!invoice.id) return;

        setIsLoading(true);
        const res = await invoiceApi.getInvoice(invoice.id, true, true);
        if (res?.referencedInvoice) {
            res.items = res.items.map(element => {
                const netPrice = element.netPrice ? element.netPrice * -1 : 0;
                const discount = Math.abs(element.discount);
                const refundedAmount = Math.abs(element.refundedAmount) * -1;
                return { ...element, netPrice, discount, refundedAmount };
            });
            setInvoiceData(res);
        } else {
            res.items = res.items.map(element => {
                const netPrice = element.netPrice ? element.netPrice : 0;
                const discount = (element.discount || 0) * -1;
                return { ...element, netPrice, discount };
            });
            setInvoiceData(res);
        }
        if (!reset) {
            setNewValues({});
            setCanEdit(false);
            setNewInvoiceNote('');
            clearEditableFieldsState();
        }
        const hasUnpaidPaymentLinks = (await invoiceApi.checkUnpaidPaymentLinks(invoice.id))?.result;
        if (hasUnpaidPaymentLinks) {
            setUnpaidPaymentLink(true);
        }

        practitionerApi.query().then(res => setPractitionerList(res.items));
        practitionerApi.listActivePractitioners().then(res => setActivePractitioners(res));

        const currentUser = await AuthApi.getMe();
        await practitionerApi.listPractitionerClinics(currentUser.id).then(res => {
            setMyClinics(res);
        });
        await getClinic(res.clinic).then(resp => {
            setCurrentClinic(resp);
        });

        setIsLoading(false);
    };

    useEffect(() => {
        if (!invoice.id) return;
        reload();
        // eslint-disable-next-line
    }, [invoice.id]);

    useEffect(() => {
        if (invoiceData) {
            setInvoiceNote(invoiceData.description);
            if (invoiceData.description !== invoiceNote && invoiceData.id) {
                const description = { description: invoiceNote };
                invoiceApi.updateInvoice(invoiceData.id, description);
            }
        }
        // eslint-disable-next-line
    }, [invoiceData]);

    // calculate data to use on invoice summary
    const { invoiceSubTotal, invoiceTotal, invoiceTax, invoiceDiscount } = useMemo(() => {
        return getTotalsFromInvoiceItems(
            canEdit ? newValues.items : invoiceData?.items,
            '',
            invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND
        );
    }, [invoiceData, newValues, canEdit]);

    const paymentTotal = useMemo(() => {
        const paymentsData = canEdit ? newValues?.payments : invoiceData.payments;
        if (!paymentsData) return;

        const payments = paymentsData.map(payment => payment.amount);
        return payments.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }, [invoiceData.payments, newValues, canEdit]);

    const totalOutstandingAmount = useMemo(() => {
        return (invoiceTotal - paymentTotal).toFixed(2);
    }, [paymentTotal, invoiceTotal]);

    const handleClose = () => {
        setNewValues({});
        setCanEdit(false);
        setNewInvoiceNote('');

        clearEditableFieldsState();

        if (!referenceInvoice) closeInvoiceModal();
        else {
            openInvoice(referenceInvoice);
            setReferenceInvoice(undefined);
        }
    };
    const handleCloseMenu = () => setAnchorEl(null);

    const openInvoice = invoice => {
        loadInvoice(invoice);
    };

    const handleUpdateVoid = async () => {
        const currentUser = await AuthApi.getMe();
        await invoiceApi.updateInvoiceStatus(invoice.id, INVOICE_PAYMENT_STATUS.VOID, currentUser.data);
        await invoiceApi.getInvoice(invoice.id, false, true).then(setInvoiceData);
        if (source === 'calendar') {
            listUpdate();
        }
    };

    const handleRefund = async () => {
        openRefundModal();
        handleClose();
    };

    const handleResendToXero = async () => {
        await XeroApi.sendToXero(invoice.id);
        toastr.success('Invoice synced with Xero');
        await reload();
        handleClose();
    };

    const havePayments = () => {
        return (
            invoiceData.paymentStatus !== INVOICE_PAYMENT_STATUS.UNPAID &&
            invoiceData.paymentStatus !== INVOICE_PAYMENT_STATUS.VOID &&
            invoiceData.paymentStatus !== INVOICE_PAYMENT_STATUS.REFUND
        );
    };

    const updateInvoice = async () => {
        setDisabledButton(true);
        const invoiceId = newValues.id;

        let invoiceItems = [];
        let haveIncorrectNetPrice = false;
        if (isRefundOrRelated) {
            invoiceItems = newValues.items.map(item => {
                return { id: item.id, soldBy: item.soldBy?.id };
            });
        } else {
            invoiceItems = newValues.items.map(item => {
                return { id: item.id, soldBy: item.soldBy?.id, netPrice: item.netPrice };
            });

            haveIncorrectNetPrice = invoiceItems.find(
                el => el?.netPrice === undefined || isNaN(el?.netPrice) || el?.netPrice === null
            );
        }

        const haveUndefined = invoiceItems.find(el => {
            const item = newValues.items.find(newValueItem => newValueItem.id === el.id);
            if (!item) {
                return true; // force error
            }

            return el.soldBy === undefined && item?.type !== 'Void';
        });

        const paymentsHasChanged = invoiceData.payments.some(data => {
            return newValues.payments.some(newValue => {
                return data.id === newValue.id
                    ? newValue.amount !== data.amount ||
                          newValue.method !== data.method ||
                          newValue.createdAt !== data.createdAt
                    : false;
            });
        });

        let invoicePayments = [];
        if (paymentsHasChanged && !isRefundOrRelated) {
            invoicePayments = newValues.payments.map(item => {
                return { id: item.id, amount: item.amount, method: item.method, createdAt: item.createdAt };
            });
        }

        if (haveUndefined) {
            toastr.error('Sold by cannot be blank');
            setDisabledButton(false);
            return;
        }

        if (haveIncorrectNetPrice) {
            toastr.error('Net price value is not valid');
            setDisabledButton(false);
            return;
        }

        try {
            await invoiceApi.updateInvoiceCC(invoiceId, {
                invoiceItems,
                invoicePayments,
                newInvoiceNote: newInvoiceNote || undefined
            });

            toastr.success('Invoice successfully updated');
            setDisabledButton(false);
            await reload();

            if (listUpdate) {
                listUpdate();
            }
        } catch (err) {
            if (typeof err === 'object') {
                if (err.data && err.data.message) {
                    toastr.error(err.data.message);
                    return;
                }
            }
            setDisabledButton(false);
            toastr.error('Something went wrong');
        }
    };

    const changeEdit = async () => {
        if (!canEdit) {
            await reload(true);
        }

        if (canEdit) {
            clearEditableFieldsState();
        }

        setNewValues(canEdit ? {} : invoiceData);
        setCanEdit(!canEdit);
        setNewInvoiceNote('');
    };

    const getClinicDetails = () => {
        return [
            currentClinic?.organisationName,
            currentClinic?.address?.line1,
            currentClinic?.address?.city,
            currentClinic?.address?.postcode,
            invoiceData?.organization?.vatNo ? 'VAT no:' + invoiceData?.organization?.vatNo : ''
        ]
            .filter(el => el)
            .join(', ');
    };

    // used when it's not on CC
    const getCustomerName = customer => {
        return (
            <a
                target={'_blank'}
                rel="noopener noreferrer"
                href={`${JSON.parse(localStorage.getItem('linkCache')).calendarUrl}/customer/${customer?.id}/general`}
                style={{ color: '#0000EE', textDecoration: 'underline', cursor: 'pointer' }}
            >
                {customer?.firstName || ''} {customer?.surname || ''}
            </a>
        );
    };

    const colors = ['#1565C0', '#303F9F', '#512DA8', '#00695C'];

    const RenderShortNotes = () => {
        if (!invoiceData.modifies?.map) return <></>;

        const SeeMoreBtn = () => (
            <b style={{ color: '#15F' }} onClick={() => setModifiedsLogModel(true)}>
                ... see more
            </b>
        );

        function shortNote(note) {
            if (note.description.length < 60) return <>{note.description}</>;
            return (
                <span>
                    {note.description.substring(0, 60)} <SeeMoreBtn />
                </span>
            );
        }

        const mapShortNotes = invoiceData.modifies
            .map((note, i) => {
                const color = i >= colors.length ? colors[i % 4] : colors[i];

                return (
                    <Typography style={{ color }} key={i + '-note-key'}>
                        <b>{note.modifiedBy.displayName}</b>: {shortNote(note)}
                    </Typography>
                );
            })
            .reverse();
        return <Box>{mapShortNotes}</Box>;
    };

    const RenderLogTableRows = () => {
        let modifiesMap = invoiceData?.modifies || [];
        const logs = [...modifiesMap, ...(invoiceData?.invoiceLogs || [])];
        logs.sort((a, b) => {
            return new Date(a?.date) - new Date(b?.date);
        });

        return logs
            .map((change, i) => {
                return (
                    <TableRow key={i + '-log-table-key'}>
                        <TableCell className={classes.tableBodyCell}>
                            {Moment(change.date).format('DD/MM/YY HH:mm')}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                            {change?.modifiedBy?.displayName || change.practitioner?.displayName}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>{change.description}</TableCell>
                    </TableRow>
                );
            })
            .reverse();
    };

    const showVoidOutstanding = useMemo(() => {
        if (!invoiceData) return false;
        if (invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.PAID) return false;

        // == instead of ===  because the expression returns a float
        // eslint-disable-next-line
        if (Math.abs(invoiceTotal - paymentTotal).toFixed(2) == 0) return false; // no outstanding

        if (invoiceData.relatedInvoices && invoiceData.relatedInvoices.length > 0) {
            // if there is some related invoice with refund
            return invoiceData.relatedInvoices.some(relatedInvoice => relatedInvoice.origin === INVOICE_ORIGIN.REFUND);
        }
        return false;
    }, [invoiceData, invoiceTotal, paymentTotal]);

    const handleVoidOutstanding = async () => {
        try {
            await invoiceApi.voidOutstandingAmount(invoice.id);
            toastr.success('Amount successfuly voided');
            await reload();
            if (listUpdate) {
                listUpdate();
            }
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                return toastr.error(err.response.data.message);
            }

            toastr.error('Something went wrong');
        }
    };

    const [voidPopupDisabledButtons, setVoidPopupDisabledButtons] = useState(false);

    const calcInvoiceItemValues = useCallback(
        element => {
            const { netPrice, discount, quantity, tax, soldBy, refundedAmount } = element;
            const taxValue = 1 + (element?.tax || 0);
            const taxLabel = (element?.tax ?? 0) * 100 + '%';
            const isRefunded = invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND;

            const elementValue = () => {
                if (isRefunded) return refundedAmount;
                return quantity * netPrice + discount / taxValue;
            };

            // calculate unitary tax, using partial discounts for quantity > 1
            const unitaryTax = calculateTaxValue({
                tax,
                netPrice: elementValue() / quantity,
                quantity: 1
            });

            // calculate total taxes, using total net price (with discounts)
            const totalTax = () => {
                if (isRefunded) return refundedAmount - refundedAmount / taxValue;
                return calculateTaxValue({
                    tax: tax,
                    netPrice: elementValue(),
                    quantity: 1
                });
            };
            // calculate total gross price first, then remove tax and discount
            const netPriceLabel = () => {
                if (isRefunded) {
                    return roundTwoDecimals(refundedAmount / taxValue);
                }
                return roundTwoDecimals(netPrice + unitaryTax) * quantity - totalTax() + discount / taxValue;
            };

            const elementTotal = () => {
                if (isRefunded) return refundedAmount;
                return (
                    element.quantity *
                        (calculateTaxValue({
                            tax: element.tax,
                            netPrice: element.netPrice,
                            quantity: 1
                        }) +
                            element.netPrice) +
                    element.discount
                );
            };

            const soldByLabel = soldBy ? element.soldBy?.displayName : '-';

            return {
                hasTax: taxValue !== 1,
                tax: taxLabel,
                totalTax: totalTax(),
                netPrice: netPriceLabel(),
                elementTotal: elementTotal(),
                soldBy: soldByLabel
            };
        },
        // eslint-disable-next-line
        [invoiceData.items]
    );

    const TableBodyTotalPrice = (element, elementTotal) => {
        if (element.netPrice !== 0) {
            if (`${Math.round(parseFloat(element.tax), 10)}%`) {
                return toLocaleString(elementTotal);
            } else {
                return `${getCurrencySymbol()}0.00`;
            }
        } else {
            return '-';
        }
    };

    const sendEmail = async () => {
        try {
            await InvoiceApi.sendEmailInvoice(invoiceData.id);
            toastr.success('Invoice successfuly sended');
            await reload();
        } catch (err) {
            toastr.error('Something went wrong');
        }
    };

    const hasRefundedInvoice = () => {
        if (invoiceData?.relatedInvoices?.length) {
            return !!invoiceData.relatedInvoices.find(ri => ri.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND);
        }

        return false;
    };

    const canRefundInvoice = () => {
        return havePayments() && !hasRefundedInvoice();
    };

    const canVoidInvoice = () => {
        // verify as not allowed payment methods.
        const notAllowedPayments = (invoiceData?.payments || []).find(payment => {
            const hasAnyCardValue =
                payment?.cardBrand ||
                payment?.cardLast4 ||
                payment?.stripePaymentIntent ||
                payment?.stripePaymentId ||
                payment?.squarePaymentId;

            const isBlockedPaymentMethod = blockedEditPaymentMethods.includes(payment?.method);

            return hasAnyCardValue || isBlockedPaymentMethod;
        });

        return !notAllowedPayments && !isRefundOrRelated && invoiceData?.paymentStatus !== INVOICE_PAYMENT_STATUS.VOID;
    };

    const isRefundOrRelated = useMemo(() => {
        if (invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.REFUND) return true;
        if (invoiceData.relatedInvoices && invoiceData.relatedInvoices.length > 0) {
            // if there is some related invoice with refund
            return invoiceData.relatedInvoices.some(relatedInvoice => relatedInvoice.origin === INVOICE_ORIGIN.REFUND);
        }
    }, [invoiceData]);

    const canChangePaymentMethod = payment => {
        if (!payment?.method) return false;
        if (isRefundOrRelated) return false;

        // blocked payment methods.
        const isCardMethod = blockedEditPaymentMethods.includes(payment.method);

        // verify has any card information.
        const hasAnyCardValue =
            payment?.cardBrand ||
            payment?.cardLast4 ||
            payment?.stripePaymentIntent ||
            payment?.stripePaymentId ||
            payment?.squarePaymentId;

        return !isCardMethod && !hasAnyCardValue;
    };
    const paymentMethodFieldHandler = (value, id, reason) => {
        setEditPaymentMethodId(null);
        if (!value?.name || reason === 'clear') return false;

        const index = (newValues?.payments || []).findIndex(payment => payment.id === id);

        if (index !== -1) {
            setNewValues(prev => {
                const payments = [...prev.payments];
                payments[index].method = value.name;

                return {
                    ...prev,
                    payments
                };
            });
        }
    };
    const paymentAmountFieldHandler = (value, id) => {
        setEditPaymentAmountId(null);
        const index = (newValues?.payments || []).findIndex(item => item.id === id);

        if (index !== -1) {
            setNewValues(prev => {
                const payments = [...prev.payments];
                payments[index].amount = parseFloat(value);

                return {
                    ...prev,
                    payments
                };
            });
        }
    };

    const paymentDateFieldHandler = (value, id) => {
        setEditPaymentDateId(null);
        const index = (newValues?.payments || []).findIndex(item => item.id === id);
        if (index !== -1) {
            setNewValues(prev => {
                const payments = [...prev.payments];
                payments[index].createdAt = value;

                return {
                    ...prev,
                    payments
                };
            });
        }
    };

    const soldByFieldHandler = (value, id) => {
        setEditSoldByItemId(null);
        const index = (newValues?.items || []).findIndex(item => item.id === id);

        if (index !== -1) {
            setNewValues(prev => {
                const items = [...prev.items];
                items[index].soldBy = value;

                return {
                    ...prev,
                    items
                };
            });
        }
    };
    const totalAmountFieldHandler = (value, id) => {
        setEditTotalItemId(null);
        const index = (newValues?.items || []).findIndex(item => item.id === id);

        if (index !== -1) {
            setNewValues(prev => {
                const items = [...prev.items];

                if (!value) {
                    items[index].netPrice = 0;
                } else {
                    const parsedValue = parseFloat(value);

                    if (parsedValue === 0) {
                        items[index].netPrice = 0;
                    } else if (parsedValue < 0) {
                        if (items[index]?.type === 'Void') {
                            items[index].netPrice = parsedValue;
                        } else {
                            items[index].netPrice = 0;
                        }
                    } else {
                        const tax = 1 + (items[index].tax || 0);
                        const discount = Math.abs(items[index].discount || 0);
                        const quantity = items[index].quantity || 1;

                        let netPrice = (parsedValue + discount) / tax;
                        if (netPrice > 0) {
                            items[index].netPrice = parseFloat((netPrice / quantity).toFixed(2));
                        } else {
                            items[index].netPrice = 0;
                        }
                    }
                }

                return {
                    ...prev,
                    items
                };
            });
        }
    };

    const renderPaymentMethodCell = (canEditPaymentMethod, element) => {
        if (canEdit && canEditPaymentMethod && editPaymentMethodId === element.id) {
            return (
                <Autocomplete
                    id="payment-method-autocomplete"
                    className={classes.editCellAutocomplete}
                    options={paymentOptions}
                    loadingText={'Loading...'}
                    clearText="Close"
                    defaultValue={(paymentOptions || []).find(option => option.name === element.method)}
                    getOptionLabel={option => option.label}
                    style={{ width: 200 }}
                    renderInput={params => (
                        <TextField {...params} label="" variant="outlined" className={classes.staffSelection} />
                    )}
                    onChange={(e, value, reason) => paymentMethodFieldHandler(value, element.id, reason)}
                />
            );
        }

        return (
            <div
                onClick={() => {
                    if (canEditPaymentMethod && canEdit && paymentOptions?.length) {
                        setEditPaymentMethodId(element.id);
                    }
                }}
            >
                {element.squarePaymentId ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://${
                            process.env.REACT_APP_ENVIRONMENT === 'production' ? 'squareup' : 'squareupsandbox'
                        }.com/dashboard/sales/transactions/${element.squarePaymentId}`}
                        style={{ color: '#0000EE', textDecoration: 'underline' }}
                    >
                        {element.method} {element.cardBrand && `${element.cardBrand} ${element.cardLast4}`}
                    </a>
                ) : (
                    <span>
                        {`${element.method} ${element.giftcardId ? '- ' + element.giftcardId.code : ''}`}{' '}
                        {element.cardBrand && `${element.cardBrand} ${element.cardLast4}`}
                    </span>
                )}
            </div>
        );
    };
    const renderPaymentAmountCell = (canEditPaymentMethod, element) => {
        const getNumericValue = value => {
            if (!value) {
                if (value === '') {
                    return value;
                } else {
                    return 0;
                }
            }

            return value;
        };

        return (
            <>
                {canEdit && canEditPaymentMethod && editPaymentAmountId === element.id ? (
                    <>
                        <EditableCell
                            defaultValue={getNumericValue(element.amount)}
                            onConfirm={value => paymentAmountFieldHandler(value, element.id)}
                            onCancel={() => {
                                setEditPaymentAmountId(null);
                            }}
                        >
                            {({ value, setValue }) => (
                                <>
                                    <TextField
                                        className={classes.editCellInput}
                                        value={value}
                                        type="number"
                                        variant="outlined"
                                        onChange={event => {
                                            const value = event.target.value;

                                            if (+value < 0) {
                                                toastr.error('Error', 'This value cannot be negative');
                                            } else {
                                                setValue(value);
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </EditableCell>
                    </>
                ) : (
                    <div
                        onClick={() => {
                            if (canEditPaymentMethod && canEdit && paymentOptions?.length) {
                                setEditPaymentAmountId(element.id);
                            }
                        }}
                    >
                        {toLocaleString(element.amount)}
                    </div>
                )}
            </>
        );
    };

    const renderPaymentDateCell = (canEditPaymentMethod, element) => {
        return (
            <>
                {canEdit && editPaymentDateId === element.id ? (
                    <EditableCell
                        defaultValue={element.createdAt}
                        onConfirm={value => paymentDateFieldHandler(value, element.id)}
                        onCancel={() => {
                            setEditPaymentDateId(null);
                        }}
                        toLeft={true}
                    >
                        {({ value, setValue }) => (
                            <>
                                <DateTimePicker
                                    format="dd/MM/yyyy HH:mm"
                                    className={classes.editCellInput}
                                    value={value}
                                    okLabel={'Continue'}
                                    TextFieldComponent={props => <TextField {...props} variant="outlined" />}
                                    onChange={value => {
                                        setValue(value);
                                    }}
                                />
                            </>
                        )}
                    </EditableCell>
                ) : (
                    <div
                        onClick={() => {
                            setEditPaymentDateId(element.id);
                        }}
                    >
                        {Moment(element.createdAt).format('DD/MM/YYYY HH:mm')}
                    </div>
                )}
            </>
        );
    };

    const renderInvoiceItemSoldByCell = element => {
        const soldByLabel = element?.soldBy ? element.soldBy?.displayName : '-';
        return (
            <>
                {canEdit && editSoldByItemId === element.id ? (
                    <>
                        <Autocomplete
                            id="soldBy-autocomplete"
                            className={classes.editCellAutocomplete}
                            options={activePractitioners}
                            defaultValue={element.soldBy ? element.soldBy : null}
                            getOptionLabel={option => option.displayName}
                            style={{ width: 200 }}
                            renderInput={params => (
                                <TextField {...params} label="" variant="outlined" className={classes.staffSelection} />
                            )}
                            onChange={(e, newSoldBy) => {
                                soldByFieldHandler(newSoldBy, element.id);
                            }}
                        />
                    </>
                ) : (
                    <div
                        onClick={() => {
                            setEditSoldByItemId(element.id);
                        }}
                    >
                        {soldByLabel}
                    </div>
                )}
            </>
        );
    };
    const renderInvoiceItemTotalCell = (element, elementTotal) => {
        return (
            <>
                {canEdit && editTotalItemId === element.id && !isRefundOrRelated ? (
                    <EditableCell
                        defaultValue={elementTotal}
                        onConfirm={value => totalAmountFieldHandler(value, element.id)}
                        onCancel={() => {
                            setEditTotalItemId(null);
                        }}
                    >
                        {({ value, setValue }) => (
                            <>
                                <TextField
                                    className={classes.editCellInput}
                                    value={value}
                                    type="number"
                                    variant="outlined"
                                    onChange={event => {
                                        const value = event.target.value;

                                        if (value < 0 && element?.type !== 'Void') {
                                            toastr.error('Error', 'This value cannot be negative');
                                        } else {
                                            setValue(value);
                                        }
                                    }}
                                />
                            </>
                        )}
                    </EditableCell>
                ) : (
                    <div
                        onClick={() => {
                            if (canEdit) {
                                setEditTotalItemId(element.id);
                            }
                        }}
                    >
                        {TableBodyTotalPrice(element, elementTotal)}
                    </div>
                )}
            </>
        );
    };

    const tableInvoicePaymentsData = useMemo(() => {
        return canEdit && !_.isEmpty(newValues) ? newValues.payments : invoiceData.payments;
    }, [canEdit, newValues, invoiceData]);

    const tableInvoiceItemsData = useMemo(() => {
        return canEdit && !_.isEmpty(newValues) ? newValues.items : invoiceData.items;
    }, [canEdit, newValues, invoiceData]);

    const canShowOutstandingAmount = () => {
        if (!canEdit) return invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID;
        return true;
    };

    const canShowPaymentLink = () => {
        return [INVOICE_PAYMENT_STATUS.PART_PAID, INVOICE_PAYMENT_STATUS.UNPAID].includes(invoiceData.paymentStatus);
    };

    return (
        <Dialog
            open={(source === 'calendar' ? invoice.isInvoiceModalOpen : true) && invoiceData && practitionerList}
            maxWidth="1000"
            className={classes.dialogLarge}
            disableEscapeKeyDown={true}
        >
            {isLoading && <LoadingScreen />}
            <div ref={componentRef} className="invoice">
                <DialogTitle align="center">
                    <Box width="100%" style={{ display: 'none' }} className="logo-container">
                        <img
                            alt="logo"
                            style={{ maxHeight: '200px', maxWidth: '100%' }}
                            src={invoiceData?.organization?.logo}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box display="flex" flexDirection="column" width="100%">
                            <span className={`${classes.fontSize18px} ${classes.title}`}>
                                {canEdit ? 'Edit' : ''} Invoice #{invoiceData.originalCode || invoiceData.code}{' '}
                                {!!(
                                    invoiceData.version &&
                                    invoiceData.versionSentToXero &&
                                    invoiceData.version &&
                                    invoiceData.versionSentToXero
                                ) && (
                                    <img
                                        alt="Synced with xero"
                                        src="/images/xero.png"
                                        style={{ height: 43, paddingBottom: 5, verticalAlign: 'middle' }}
                                    />
                                )}
                            </span>
                            <span
                                className={
                                    [INVOICE_PAYMENT_STATUS.UNPAID, INVOICE_PAYMENT_STATUS.PART_PAID].includes(
                                        invoiceData?.paymentStatus
                                    ) && classes.fontRed
                                }
                            >
                                {invoiceData?.paymentStatus?.toUpperCase()}
                                {[INVOICE_PAYMENT_STATUS.UNPAID, INVOICE_PAYMENT_STATUS.PART_PAID].includes(
                                    invoiceData?.paymentStatus
                                ) && unpaidPaymentLink
                                    ? ' - Payment link sent'
                                    : ''}
                            </span>
                            <span>
                                {invoiceData?.referencedInvoice ? (
                                    <>
                                        Refund of original invoice{' '}
                                        <Button
                                            onClick={() => {
                                                setReferenceInvoice(invoiceData.id);
                                                openInvoice(invoiceData.referencedInvoice.id);
                                            }}
                                        >
                                            {invoiceData.referencedInvoice.originalCode ||
                                                invoiceData.referencedInvoice.code}
                                        </Button>
                                    </>
                                ) : (
                                    ''
                                )}
                            </span>
                        </Box>
                        <IconButton className={`${classes.closeButton} close-button`} onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" justifyContent="space-between" className={classes.box}>
                        <Box display="flex" flexDirection="column">
                            <Typography className={classes.marginTop8px}>
                                Date: {Moment(invoiceData?.date).format('DD/MM/YYYY')}
                            </Typography>
                            {source === 'calendar' && (
                                <Typography className={classes.marginTop8px}>
                                    Name: {invoiceData.customerData && getCustomerName(invoiceData.customerData[0])}
                                </Typography>
                            )}
                            <Typography className={classes.marginTop8px}>
                                Closed by: {invoiceData.closedBy && invoiceData.closedBy?.displayName}
                            </Typography>
                            {source !== 'calendar' && (
                                <Typography className={classes.marginTop8px}>
                                    Customer: {invoiceData.customerData && getCustomerName(invoiceData.customerData[0])}
                                </Typography>
                            )}
                        </Box>
                        <Box display="flex" alignItems="flex-end">
                            {source === 'calendar' && (
                                <div className="options-button">
                                    <Button
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        color="primary"
                                        className={classes.button}
                                        onClick={event => setAnchorEl(event.currentTarget)}
                                        disabled={myClinics.map(c => c.id).includes(invoiceData.clinic) === false}
                                    >
                                        Options
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                        className={classes.optionsMenu}
                                    >
                                        {(invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.PART_PAID ||
                                            invoiceData.paymentStatus === INVOICE_PAYMENT_STATUS.UNPAID) && (
                                            <MenuItem
                                                onClick={() => {
                                                    window.open(
                                                        `${
                                                            JSON.parse(localStorage.getItem('linkCache')).posUrl
                                                        }/sale?invoice=${invoiceData.id}`,
                                                        '_blank'
                                                    );
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Take Payment
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() => {
                                                changeEdit();
                                                handleCloseMenu();
                                                setEditPaymentAmountId(null);
                                                setEditPaymentMethodId(null);
                                            }}
                                        >
                                            {!canEdit ? 'Edit Invoice' : 'Cancel'}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                sendEmail();
                                                handleCloseMenu();
                                            }}
                                        >
                                            Email invoice
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setModifiedsLogModel(true);
                                                handleCloseMenu();
                                            }}
                                        >
                                            Invoice Log
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handlePrint();
                                                handleCloseMenu();
                                            }}
                                        >
                                            Print
                                        </MenuItem>
                                        {showVoidOutstanding && (
                                            <MenuItem
                                                onClick={() => {
                                                    setVoidOsPopupOpen(true);
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Void OS amount
                                            </MenuItem>
                                        )}
                                        {canRefundInvoice() && (
                                            <MenuItem
                                                className={classes.fontRed}
                                                onClick={() => {
                                                    handleRefund();
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Refund
                                            </MenuItem>
                                        )}
                                        {canShowPaymentLink() && (
                                            <MenuItem
                                                onClick={() => {
                                                    setPaymentLinkModel(true);
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Send payment link
                                            </MenuItem>
                                        )}
                                        {canVoidInvoice() && (
                                            <MenuItem
                                                className={classes.fontRed}
                                                onClick={() => {
                                                    setVoidPopupOpen(true);
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Void
                                            </MenuItem>
                                        )}
                                        {isXeroConnected && (
                                            <MenuItem
                                                onClick={() => {
                                                    handleResendToXero();
                                                    handleCloseMenu();
                                                }}
                                            >
                                                Re-sync to Xero
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Typography className={classes.boldFont}>Invoice Items</Typography>
                        <Table className={classes.table} size="medium" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {itemsHeader.map((element, index) => {
                                        return (
                                            <TableCell
                                                key={`${index}-items-header`}
                                                className={classes.tableHeader}
                                                align={element.numeric ? 'right' : 'left'}
                                            >
                                                {element.name}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(tableInvoiceItemsData || [])?.map((element, index) => {
                                    const { totalTax, netPrice, elementTotal } = calcInvoiceItemValues(element);
                                    return (
                                        <TableRow key={`${index}-item-table`} className={classes.tableRow}>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${canEdit ? 'editable' : ''}`}
                                            >
                                                {renderInvoiceItemSoldByCell(element)}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {element.name}
                                                {element.isRedemption && (
                                                    <>
                                                        <br />
                                                        <b>- Redeemed</b>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.quantity}
                                            </TableCell>

                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.discount !== 0 && invoiceData.origin !== 'REFUND'
                                                    ? toLocaleString(element.discount || 0)
                                                    : '-'}
                                            </TableCell>

                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.netPrice !== 0 ? toLocaleString(netPrice) : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell} style={{ textAlign: 'end' }}>
                                                {element.netPrice === 0 ? '-' : toLocaleString(totalTax)}
                                            </TableCell>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${
                                                    canEdit && !isRefundOrRelated ? 'editable' : ''
                                                }`}
                                                style={{ textAlign: 'end' }}
                                            >
                                                {renderInvoiceItemTotalCell(element, elementTotal)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box mt={2}>
                        <Typography className={classes.boldFont}>Invoice notes:</Typography>
                        <Box ml={1}>{RenderShortNotes()}</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="space-between">
                        {canEdit && (
                            <Box width="50%" height="100%">
                                <TextField
                                    multiline
                                    label="New note"
                                    value={newInvoiceNote}
                                    className={classes.notesInput}
                                    rows={4}
                                    onChange={e => setNewInvoiceNote(e.target.value)}
                                    variant="outlined"
                                />
                            </Box>
                        )}
                        <Box>
                            <Typography>{invoiceData.temporaryDiscount?.name || ''}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" textAlign="right" className={classes.invoiceDetails}>
                            <Typography>
                                Net Total:
                                <span>
                                    {toLocaleString(
                                        invoiceData?.paymentStatus === INVOICE_PAYMENT_STATUS.VOID && !canEdit
                                            ? 0
                                            : invoiceSubTotal
                                    )}
                                </span>
                            </Typography>
                            <Typography className={classes.marginTop8px}>
                                Tax:
                                <span>
                                    {toLocaleString(
                                        invoiceData?.paymentStatus === INVOICE_PAYMENT_STATUS.VOID && !canEdit
                                            ? 0
                                            : invoiceTax
                                    )}
                                </span>
                            </Typography>
                            <Typography className={`${classes.boldFont} ${classes.marginTop8px}`}>
                                Grand Total:
                                <span>
                                    {toLocaleString(
                                        invoiceData?.paymentStatus === INVOICE_PAYMENT_STATUS.VOID && !canEdit
                                            ? 0
                                            : parseFloat(invoiceTotal.toFixed(2))
                                    )}
                                </span>
                            </Typography>
                            {invoiceDiscount !== 0 && invoiceData.origin !== 'REFUND' && (
                                <Typography className={classes.marginTop8px}>
                                    Inc Gross Discount:
                                    <span>
                                        {toLocaleString(
                                            invoiceData?.paymentStatus === INVOICE_PAYMENT_STATUS.VOID && !canEdit
                                                ? 0
                                                : invoiceDiscount
                                        )}
                                    </span>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box mt={canEdit ? 3 : 5}>
                        <Box>
                            <Typography className={classes.boldFont}>Payment</Typography>
                        </Box>
                        <Table className={classes.table} size="medium" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {paymentsHeader.map((element, index) => {
                                        return (
                                            <TableCell
                                                key={`${index}-payments-header`}
                                                className={classes.tableHeader}
                                                align={element.numeric ? 'right' : 'left'}
                                            >
                                                {element.name}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(tableInvoicePaymentsData || [])?.map((element, index) => {
                                    const canUpdatePaymentMethod = canChangePaymentMethod(element);
                                    const isCardMethod = blockedEditPaymentMethods.includes(element.method);

                                    return (
                                        <TableRow key={`${index}-payment-table`} className={classes.tableRow}>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${
                                                    canEdit && !isCardMethod ? 'editable' : ''
                                                }`}
                                            >
                                                {renderPaymentDateCell(!isCardMethod, element)}
                                            </TableCell>
                                            <TableCell className={classes.tableBodyCell}>
                                                {element.takenBy?.displayName}
                                            </TableCell>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${
                                                    canEdit && canUpdatePaymentMethod && paymentOptions?.length
                                                        ? 'editable'
                                                        : ''
                                                }`}
                                            >
                                                {renderPaymentMethodCell(canUpdatePaymentMethod, element)}
                                            </TableCell>
                                            <TableCell
                                                className={`${classes.tableBodyCell} ${
                                                    canEdit && canUpdatePaymentMethod ? 'editable' : ''
                                                }`}
                                                style={{ textAlign: 'end' }}
                                            >
                                                {renderPaymentAmountCell(canUpdatePaymentMethod, element)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Box mt={2} display="flex" flexDirection="column" alignItems="flex-end" mb={1.5}>
                            <Typography className={classes.boldFont}>
                                Total:{' '}
                                {toLocaleString(
                                    invoiceData?.paymentStatus === INVOICE_PAYMENT_STATUS.VOID && !canEdit
                                        ? 0
                                        : paymentTotal
                                )}
                            </Typography>
                            {canShowOutstandingAmount() && (
                                <Typography className={`${classes.boldFont} ${classes.fontRed}`}>
                                    OUTSTANDING: {toLocaleString(invoiceTotal - paymentTotal)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Typography className={`${classes.centered} ${classes.font14}`}>
                            {getClinicDetails()}
                        </Typography>
                    </Box>
                    {canEdit && (
                        <Box mt={1} className={classes.buttonsBox}>
                            <Button className={classes.cancelBtn} onClick={changeEdit}>
                                Cancel
                            </Button>
                            <Button className={classes.button} onClick={updateInvoice} disabled={disabledButton}>
                                Save
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </div>
            <CancelContinueModal
                open={voidPopupOpen}
                title="Void Invoice"
                contentHtml={
                    '<p>Are you sure you want to void this invoice and associated payments?</p><br />' +
                    '<p style="text-align: left;padding-left: 20px;">If you void this invoice:</p><ul style="padding-left: 20px;">' +
                    '<li><p style="text-align: left;">any account items it contains will be removed from the client’s account.</p></li>' +
                    '<li><p style="text-align: left;">any products will have their stock adjusted (if you are using stock control).</p></li>' +
                    '<li><p style="text-align: left;">any courses will be voided.</p></li>' +
                    '<li><p style="text-align: left;">any redeemed course treatments will be unredeemed</p></li>' +
                    (invoiceData?.items?.find(el => el.type === 'Course')
                        ? '<li><p style="text-align: left;">any future services booked from this course will no longer be connected to it. They will become single services.</p></li>'
                        : '') +
                    '</ul>'
                }
                onContinue={() => {
                    setVoidPopupDisabledButtons(true);
                    handleUpdateVoid().then(() => {
                        closeInvoiceModal();
                        setVoidPopupDisabledButtons(false);
                        setVoidPopupOpen(false);
                        handleCloseMenu();
                    });
                }}
                onCancel={() => {
                    setVoidPopupOpen(false);
                    setVoidPopupDisabledButtons(false);
                }}
                closeOnBlur
                disableButtons={voidPopupDisabledButtons}
            />
            <CancelContinueModal
                open={voidOsPopupOpen}
                title="Void Invoice"
                contentHtml={'<p>Are you sure you want to void OS amount?</p>'}
                onContinue={() => {
                    handleVoidOutstanding().then(() => {
                        closeInvoiceModal();
                        setVoidOsPopupOpen(false);
                        handleCloseMenu();
                    });
                }}
                cancelButtonText={'Back'}
                continueButtonText={'Void OS amount'}
                onCancel={() => {
                    setVoidOsPopupOpen(false);
                    handleCloseMenu();
                }}
                closeOnBlur
            />
            <Dialog
                open={modifiedsLogModel}
                className={classes.dialog}
                onBackdropClick={() => setModifiedsLogModel(false)}
                onEscapeKeyDown={() => setModifiedsLogModel(false)}
            >
                <Box minWidth={500} minHeight={500}>
                    <DialogTitle>
                        <Typography align="center">
                            <b className={`${classes.fontSize18px} ${classes.title}`}>Invoice Log</b>
                        </Typography>
                    </DialogTitle>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {changeLogHeader.map((head, i) => {
                                    return (
                                        <TableCell className={classes.tableHeader} key={i}>
                                            {head.name}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>{RenderLogTableRows()}</TableBody>
                    </Table>
                </Box>
                <IconButton className={classes.closeButton} onClick={() => setModifiedsLogModel(false)}>
                    <CloseIcon />
                </IconButton>
            </Dialog>
            {paymentLinkModel && (
                <PaymentLinkDialog
                    setPaymentLinkModel={setPaymentLinkModel}
                    amount={totalOutstandingAmount}
                    invoiceId={invoiceData.id}
                />
            )}
        </Dialog>
    );
};

InvoiceModal.propTypes = {
    classes: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    closeInvoiceModal: PropTypes.func.isRequired,
    loadInvoice: PropTypes.func.isRequired,
    source: PropTypes.string,
    listUpdate: PropTypes.func,
    openRefundModal: PropTypes.func
};

export default withStyles(InvoiceModalStyles)(InvoiceModal);
