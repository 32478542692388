import React, { useState } from 'react';

import styles from './styles';

import Modal from '../../../common/Modal';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography, withStyles } from '@material-ui/core';
import StyledTableRow from './../../../common/StyledTableRow';
import ViewForm from './ViewForm';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { ORGANISATION_FORM_TYPES } from '../../../../collums-constants';
import { clone } from 'lodash';

const FormHistory = ({ classes, form, onCancel }) => {
    const [viewingForm, setViewingForm] = useState();

    const showFormAtStage = index => {
        const logData = form.logs[index];
        if (form.model.type === ORGANISATION_FORM_TYPES.CONSENT) {
            const consentCopy = clone(form);
            if (!logData.signed && consentCopy.pdfUrl) {
                delete consentCopy.pdfUrl;
            }
            setViewingForm(consentCopy);
        } else {
            const data = logData.newData || {};
            const modelCopy = clone(form.model);
            (modelCopy.fields || []).forEach(field => {
                if (data[field.id]) Object.assign(field, data[field.id]);
            });
            setViewingForm({
                ...form,
                model: modelCopy
            });
        }
    };

    return (
        <>
            <Modal id="form-log" isOpen title="" size="md" onCancel={onCancel} hideTitle hideCancelButton>
                <div className={classes.header}>
                    <Typography variant="span" className={classes.title}>
                        Form History Log
                    </Typography>
                </div>
                <div>
                    {(form.logs || []).length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeader}>{'Time & Date'}</TableCell>
                                    <TableCell className={classes.tableHeader}>Action</TableCell>
                                    <TableCell className={classes.tableHeader}>Changed by</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {form.logs.map((log, index) => (
                                    <StyledTableRow key={index}>
                                        <TableCell className={classes.defaultFont}>
                                            {Moment(log.date).format('DD/MM/YYYY HH:mm')}
                                        </TableCell>
                                        <TableCell className={classes.defaultFont}>
                                            <span
                                                onClick={() => {
                                                    showFormAtStage(index);
                                                }}
                                                className={classes.link}
                                            >
                                                {log.action}
                                            </span>
                                        </TableCell>
                                        <TableCell className={classes.defaultFont}>
                                            {log.changedBy || 'ONLINE'}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Typography className={classes.defaultFont} variant="h5" align="center">
                            Form log unavailable or nonexistent.
                        </Typography>
                    )}
                </div>
            </Modal>
            {viewingForm && (
                <ViewForm selectedItem={viewingForm} type={viewingForm.model.type} onCancel={() => setViewingForm()} />
            )}
        </>
    );
};

FormHistory.propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withStyles(styles)(FormHistory);
