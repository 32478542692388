import React, { useState } from 'react';
import { Typography, withStyles, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Modal from '../../../common/Modal';
import { SaveTemplateStyles } from './styles';
import { PropTypes } from 'prop-types';
import LoadingScreen from './../../../../collums-components/components/common/loadingScreen';
import { toastr } from 'react-redux-toastr';
import TreatmentNoteApi from './../../../../collums-components/api/TreatmentNoteApi';

const SaveTemplate = ({ classes, content, closeModal, loadTemplates, type }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [allPractitioners, setAllPractitioners] = useState(false);

    const save = async () => {
        if (!name) {
            return toastr.error('Missing template name');
        }
        try {
            setIsLoading(true);
            await TreatmentNoteApi.createTemplate(type, { ...content, name, allPractitioners });
            toastr.success('Template successfully saved');
            await loadTemplates();
            closeModal();
        } catch (err) {
            return toastr.error(err?.data?.message || 'Something went wrong (code: c0051)');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Modal
                confirmLabel="Save"
                hideTitle
                id="save-template"
                isOpen
                size="xs"
                onCancel={closeModal}
                onConfirm={save}
            >
                <Typography variant="h4" className={`${classes.modalTitle} ${classes.defaultFont}`}>
                    Medical Note Text Template
                </Typography>
                <TextField
                    id="template-name"
                    required
                    label="Name"
                    value={name}
                    onChange={({ target }) => {
                        setName(target.value);
                    }}
                    variant="outlined"
                    fullWidth
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={allPractitioners}
                            onChange={(e, value) => {
                                setAllPractitioners(value);
                            }}
                        />
                    }
                    label="Save for all practitioners"
                />
            </Modal>
        </>
    );
};

SaveTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    loadTemplates: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
};

export default withStyles(SaveTemplateStyles)(SaveTemplate);
